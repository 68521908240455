import { getOrderDeliveryList } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DeliveryType, UUIDType } from '@types';

type OrderDeliveriesData = {
  data: Array<DeliveryType>;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useGetOrderDeliveries = (
  orderUUID: UUIDType
): OrderDeliveriesData => {
  const queryResults: UseQueryResult<Array<DeliveryType>, Error> = useQuery({
    queryKey: [QueryKeys.DELIVERIES, orderUUID],
    queryFn: () => getOrderDeliveryList(orderUUID),
    enabled: !!orderUUID,
  });

  const { data, isFetching, isError, refetch } = queryResults;

  return {
    data,
    isFetching,
    isError,
    refetch,
  };
};
