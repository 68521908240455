import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { AssignIcon, Box, LoadingButton, Stack } from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { CarrierType, OrderType, TourType } from '@types';
import { useTranslation } from 'react-i18next';
import { VehicleAutoCompleteTextField } from './VehicleAutoCompleteTextField';

const FormStyles = styled('form')(({ theme }) => ({
  width: '100%',
}));

const schema = yup.object().shape({
  vehicle: yup.object().required('Vehicle is required'),
});

type Props = {};

export const VehicleForm: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const {
    localExistingOrderData,
    selectedOrderUUID,
    updateLocalExistingOrderData,
  } = useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;
  const carrier: CarrierType | null = tourData?.carrier || null;

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: mutateTour, isPending } = useMutation<
    OrderType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const onSubmit = (formData: any) => {
    const id = toast.loading(t('assigningVehicle'));

    mutateTour(
      { vehicle: formData?.vehicle?.uuid },
      {
        onSuccess: (data, values) => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          tourData.vehicle = formData.vehicle;
          updateLocalExistingOrderData({
            tourData,
          });
          toast.update(id, {
            autoClose: 6000,
            type: 'success',
            render: t('vehicleSucessfullyAssigned'),
            isLoading: false,
          });
        },
        onError: (error, variables, context) => {
          toast.update(id, {
            autoClose: 6000,
            render: t('vehicleCouldNotBeAssigned'),
            type: 'error',
            isLoading: false,
          });
        },
      }
    );
  };

  return (
    <FormStyles onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={6}>
        <Controller
          name="vehicle"
          control={control}
          render={({ field, fieldState }) => (
            <VehicleAutoCompleteTextField
              label="Vehicle"
              carrier={carrier}
              {...field}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="primary"
            loading={isPending}
            startIcon={<AssignIcon />}
            disabled={!isValid}
          >
            {t('assign')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormStyles>
  );
};
