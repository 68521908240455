import { useMutation, useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createOrder, getOrder } from '@core/api';
import { QueryKeys } from '@core/config';

import { LoadingButton, PlusIcon } from '@dizzbo/ui';
import { TourOrdersFullDataProvider } from '@order-detail/contexts';
import { OrderType } from '@types';
import { CreateOrderDialog } from '../CreateOrderDialog';

interface CreateOrderProps {
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained' | 'primary' | 'secondary';
}

const CreateOrderButton: React.FC<CreateOrderProps> = ({
  size = 'large',
  variant = 'primary',
}) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderCreateDialog',
  });
  const [createdOrderData, setCreatedOrderData] = useState<OrderType>(null);

  const { isPending, data: orderData } = useQuery({
    queryKey: [QueryKeys.ORDERS, createdOrderData?.uuid],
    queryFn: () => getOrder(createdOrderData?.uuid),
    enabled: !!createdOrderData,
  });

  const createOrderMutation = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationFn: createOrder,
    onSuccess: (createdOrderdata) => {
      setCreatedOrderData(createdOrderdata);
    },
  });

  const handleOpenDialog = () => {
    createOrderMutation.mutate({});
  };

  useEffect(() => {
    if (!isPending) {
      popupState.open();
    }
  }, [isPending]);

  return (
    <>
      <LoadingButton
        type="submit"
        variant={variant}
        size={size}
        startIcon={<PlusIcon />}
        onClick={handleOpenDialog}
        loading={createOrderMutation.isPending}
      >
        {t('createNewOrder')}
      </LoadingButton>
      {!isPending && createdOrderData ? (
        <TourOrdersFullDataProvider
          contextType={'newOrder'}
          initialDisplayedOrderUUID={orderData.uuid}
          refetchOrderData={null}
          tourData={null}
          refetchTourData={null}
          tourOrders={null}
          refetchTourOrders={null}
          rearrangedTourOrdersUUIDs={null}
          newOrderData={{
            order: orderData,
          }}
        >
          <CreateOrderDialog orderData={orderData} popupState={popupState} />
        </TourOrdersFullDataProvider>
      ) : null}
    </>
  );
};

export default CreateOrderButton;
