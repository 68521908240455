import React, {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type OrderDetailNavigationContextType = {
  activeTabIndex: number;
  setActiveTabIndex: (newIndex: number) => void;
};

const NavigationContext: Context<OrderDetailNavigationContextType> =
  createContext<OrderDetailNavigationContextType | undefined>(undefined);

export const OrderDetailNavigationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(1);

  return (
    <NavigationContext.Provider value={{ activeTabIndex, setActiveTabIndex }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useOrderDetailNavigation = () => {
  const context: OrderDetailNavigationContextType =
    useContext(NavigationContext);

  if (!context) {
    throw new Error(
      'useOrderDetailNavigation hook must be used within a OrderDetailNavigationProvider'
    );
  }

  return context;
};
