import React from 'react';

import { Box, List, LoadingScreen } from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { EventListItem } from './EventListItem';
import { useGetEvents } from './hooks/useGetEvents';

type Props = {};

export const EventList: React.FC<Props> = () => {
  const { selectedOrderUUID } = useTourOrdersFullData();
  const { data, isPending } = useGetEvents(selectedOrderUUID);

  return (
    <Box sx={{ minHeight: 300, position: 'relative' }}>
      {isPending ? (
        <LoadingScreen
          sx={{
            position: 'absolute',
            background: 'rgba(255,255,255, 0.3)',
          }}
        />
      ) : (
        <List>
          {data &&
            data.map((event, index) => (
              <EventListItem key={event.uuid} event={event} />
            ))}
        </List>
      )}
    </Box>
  );
};
