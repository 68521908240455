import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardSubHeader,
  ErrorIcon,
  GoodIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';

import { deleteDeliveryGood } from '@core/api';
import { QueryKeys } from '@core/config';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { UUIDType } from '@types';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  title: string;
  deliveryUUID: UUIDType;
  deliveryGoodUUID: UUIDType;
  setActiveTab: (value: number) => void;
};

export const DeliveryGoodDetailHeader: React.FC<Props> = ({
  title,
  deliveryUUID,
  deliveryGoodUUID,
  setActiveTab,
}): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedOrderUUID } = useTourOrdersFullData();

  const { mutate: mutateDelivery }: any = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
    ],
    mutationFn: () => deleteDeliveryGood(deliveryUUID, deliveryGoodUUID),
  });

  const handleDelete = (popupState) => {
    mutateDelivery('', {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeys.ORDERS,
            selectedOrderUUID,
            QueryKeys.DELIVERY_GOODS,
          ],
        });
        setActiveTab(0);
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TODOS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TASKS, selectedOrderUUID],
        });
      },
      onError: (error, variables, context) => {},
    });
    popupState.close();
  };

  return (
    <CardSubHeader title={title} icon={<GoodIcon />}>
      <ActionsStyles>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deleteGood')}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </ActionsStyles>
    </CardSubHeader>
  );
};
