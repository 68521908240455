import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useWorkspaces } from '@dizzbo/core/hooks';
import { Button, Chip, Divider, ResetIcon, Stack } from '@dizzbo/ui';
import { useSearchValue } from '../../context';
import { ColumnsSettingsButton, DensityButton } from '../OrderList';

type Props = {
  chipsConfig: {
    [key: string]: {
      icon: React.ReactElement;
    };
  };
};

export const FillterChipList: React.FC<Props> = ({ chipsConfig }) => {
  const { t } = useTranslation();
  const {
    activeWorkspace,
    activeWorkspaceUUID,
    removeWorkspaceSettings,
    setWorkspaceSettings,
  } = useWorkspaces();
  const { setSearchValue } = useSearchValue();

  const filters = activeWorkspace?.settings?.filters || {};

  const handleDeleteFilter = (key: string, value: string) => {
    if (isEmpty(filters)) return;

    const currentFilters = filters[key];

    const currentIndex = currentFilters.indexOf(value);

    if (currentIndex === -1) {
      // do nothing?
    } else {
      currentFilters.splice(currentIndex, 1);

      if (currentFilters.length === 0) {
        removeWorkspaceSettings(activeWorkspaceUUID, 'filters', key);
      } else {
        filters[key] = currentFilters;

        setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
          [key]: currentFilters,
        });
      }
    }
  };

  const handleResetFilters = () => {
    setWorkspaceSettings(activeWorkspaceUUID, 'filters', null);
    setSearchValue('');
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        {filters &&
          Object.entries(filters).map(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
              return value.map((value) => (
                <Chip
                  icon={chipsConfig[key] && chipsConfig[key].icon}
                  key={value.value}
                  sx={{ minWidth: 16, textTransform: 'none' }}
                  label={t([value.name])}
                  variant="turquoise-100"
                  size="medium"
                  onDelete={() => handleDeleteFilter(key, value)}
                />
              ));
            }
          })}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ marginLeft: 'auto' }}
      >
        {!isEmpty(filters) && (
          <Button
            onClick={handleResetFilters}
            variant="text"
            size="small"
            startIcon={<ResetIcon />}
          >
            {t('resetFilters')}
          </Button>
        )}
        <Divider orientation="vertical" flexItem />
        <DensityButton />
        <ColumnsSettingsButton />
      </Stack>
    </>
  );
};
