import { styled, SxProps } from '@mui/material/styles';
import React, { FC } from 'react';
import { Box } from '../Box';

import { LoadingIndicator } from '../LoadingIndicator';

const RootStyle = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 999999,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#ffffff',
}));

type Props = { sx?: SxProps };

export const LoadingScreen: FC<Props> = ({ sx }) => {
  return (
    <RootStyle sx={{ ...sx }}>
      <LoadingIndicator />
    </RootStyle>
  );
};
