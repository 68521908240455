import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

import {
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { CarrierType, TourType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {};

export const CarrierDisplay: FC<Props> = () => {
  const queryClient = useQueryClient();
  const {
    localExistingOrderData,
    updateLocalExistingOrderData,
    refetchTourDataAndOrders,
  } = useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;
  const carrier: CarrierType | null = tourData.carrier;

  const { mutate: mutateTour } = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const handleDelete = (popupState) => {
    mutateTour(
      { carrier: null, vehicle: null },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          toast.success(t('carrierRemoved'));
          tourData.carrier = null;
          tourData.vehicle = null;
          updateLocalExistingOrderData({
            tourData,
          });
          refetchTourDataAndOrders();
        },
        onError: () => {
          toast.error(t('carrierCouldNotBeRemoved'));
        },
      }
    );
    popupState.close();
  };

  const customerNumber: string = carrier?.customerNumber?.toString() || '';
  const name: string = carrier?.name || '';
  const secondName: string = carrier?.secondName || '';
  const isLocked: boolean = carrier?.isLocked || false;

  return (
    <Stack direction="row" spacing={6} alignItems="center">
      <Stack>
        <Typography variant="labelSmall" color="primary.light">
          {t('carrierNo')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {customerNumber}
        </Typography>
      </Stack>
      <Stack width="100%">
        <Typography variant="labelSmall" color="primary.light">
          {t('carrier')}
        </Typography>
        <Typography variant="bodyRegular" color="primary.dark">
          {name} {secondName} {isLocked ? '🚫' : ''}
        </Typography>
      </Stack>
      <PopupState variant="popover" popupId="carrier-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDelete(popupState)}>
                <ListItemIcon>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('removeCarrier')}</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Stack>
  );
};
