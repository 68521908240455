import {
  useGetMultipleOrdersTasks,
  useGetMultipleOrdersTodos,
  useGetTourTasks,
  useGetTourTodos,
} from '@core/hooks';
import {
  OrderTodosTasksType,
  OrderTodoType,
  OrderType,
  TourTodosTasksType,
  TourTodoType,
  TourType,
  UUIDType,
} from '@types';
import React, {
  Context,
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type LocalExistingOrderDataSnapshot = {
  tourData: TourType;
  tourOrders: Record<UUIDType, OrderType>;
  tourOrdersTodos: Record<UUIDType, Array<OrderTodoType>>;
  tourOrdersTasks: Record<UUIDType, OrderTodosTasksType>;
  tourTodos: Array<TourTodoType>;
  tourTasks: TourTodosTasksType;
  rearrangedTourOrdersUUIDs: Array<UUIDType>;
};

type LocalNewOrderDataSnapshot = {
  order: OrderType;
};

type TourOrdersContextType = 'newOrder' | 'existingOrder';

type TourOrdersFullDataContextType = {
  contextType: TourOrdersContextType;
  localExistingOrderData: LocalExistingOrderDataSnapshot | null;
  localNewOrderData: LocalNewOrderDataSnapshot | null;
  selectedOrderUUID: UUIDType;
  selectedOrderData: OrderType;
  updateSelectedOrderUUID: Dispatch<SetStateAction<string>>;
  updateLocalExistingOrderData: (
    newData: Partial<LocalExistingOrderDataSnapshot>
  ) => void;
  updateLocalNewOrderData: (newData: LocalNewOrderDataSnapshot) => void;
  refetchTourDataAndOrders: () => void;
  tourOrdersUUIDS: Array<UUIDType>;
};

const TourOrdersFullDataContext: Context<TourOrdersFullDataContextType> =
  createContext<TourOrdersFullDataContextType | undefined>(undefined);

interface Props extends PropsWithChildren {
  contextType: TourOrdersContextType;
  initialDisplayedOrderUUID: UUIDType;
  refetchOrderData: () => void | null;
  tourData: TourType | null;
  refetchTourData: () => void | null;
  tourOrders: Record<UUIDType, OrderType> | null;
  refetchTourOrders: () => void | null;
  rearrangedTourOrdersUUIDs: Array<UUIDType> | null;
  newOrderData: LocalNewOrderDataSnapshot | null;
}

export const TourOrdersFullDataProvider: FC<Props> = ({
  contextType,
  initialDisplayedOrderUUID,
  refetchOrderData,
  tourData,
  refetchTourData,
  tourOrders,
  refetchTourOrders,
  rearrangedTourOrdersUUIDs,
  newOrderData,
  children,
}) => {
  const existingOrderData: LocalExistingOrderDataSnapshot = {
    tourData,
    tourOrders,
    rearrangedTourOrdersUUIDs,
    tourOrdersTasks: {},
    tourOrdersTodos: {},
    tourTasks: {
      loading_time_todo: [],
      pod_review_todo: [],
      pod_upload_todo: [],
      tour_review_todo: [],
      transport_contract_todo: [],
      unloading_time_todo: [],
    },
    tourTodos: [],
  };

  const [selectedOrderUUID, setSelectedOrderUUID] = useState<UUIDType>(
    initialDisplayedOrderUUID
  );
  const [localExistingOrderData, setLocalExistingOrderData] =
    useState<LocalExistingOrderDataSnapshot>(existingOrderData);
  const [localNewOrderData, setLocalNewOrderData] =
    useState<LocalNewOrderDataSnapshot>(newOrderData);

  const initialOrderData: OrderType =
    contextType === 'newOrder'
      ? structuredClone(newOrderData.order)
      : structuredClone(existingOrderData.tourOrders[selectedOrderUUID]);

  const [selectedOrderData, setSelectedOrderData] =
    useState<OrderType>(initialOrderData);

  const {
    data: ordersTasks,
    isFetching: isOrdersTasksFetching,
    refetch: refetchOrderTasks,
  } = useGetMultipleOrdersTasks(rearrangedTourOrdersUUIDs || []);

  const {
    data: ordersTodos,
    isFetching: isOrdersTodosFetching,
    refetch: refetchOrderTodos,
  } = useGetMultipleOrdersTodos(rearrangedTourOrdersUUIDs || []);

  const {
    data: tourTasks,
    isFetching: isTourTasksFetching,
    refetch: refetchTourTasks,
  } = useGetTourTasks(contextType === 'existingOrder' ? tourData.uuid : null);

  const {
    data: tourTodos,
    isFetching: isTourTodosFetching,
    refetch: refetchTourTodos,
  } = useGetTourTodos(contextType === 'existingOrder' ? tourData.uuid : null);

  useEffect(() => {
    if (
      !isOrdersTasksFetching &&
      !isOrdersTodosFetching &&
      !isTourTasksFetching &&
      !isTourTodosFetching &&
      ordersTasks &&
      ordersTodos &&
      tourTasks &&
      tourTodos &&
      contextType === 'existingOrder'
    ) {
      setLocalExistingOrderData((prevState: LocalExistingOrderDataSnapshot) => {
        const newState: LocalExistingOrderDataSnapshot =
          structuredClone(prevState);
        newState.tourOrdersTasks = structuredClone(ordersTasks);
        newState.tourOrdersTodos = structuredClone(ordersTodos);
        newState.tourTasks = structuredClone(tourTasks);
        newState.tourTodos = [...tourTodos];
        return newState;
      });
    }
  }, [
    isOrdersTasksFetching,
    isOrdersTodosFetching,
    isTourTasksFetching,
    isTourTodosFetching,
  ]);

  function updateLocalExistingOrderData(
    newData: Partial<LocalExistingOrderDataSnapshot>
  ): void {
    if (!newData) {
      console.error(
        `Error in updateLocalExistingOrderData()! Undefined newData!`
      );
      return;
    }
    setLocalExistingOrderData((prevState: LocalExistingOrderDataSnapshot) => {
      const newState: LocalExistingOrderDataSnapshot =
        structuredClone(prevState);
      if (newData.tourData) {
        newState.tourData = structuredClone(newData.tourData);
      }
      if (newData.tourOrders) {
        newState.tourOrders = structuredClone(newData.tourOrders);
      }
      if (newData.tourOrdersTodos) {
        newState.tourOrdersTodos = structuredClone(newData.tourOrdersTodos);
      }
      if (newData.tourOrdersTasks) {
        newState.tourOrdersTasks = structuredClone(newData.tourOrdersTasks);
      }
      if (newData.tourTodos) {
        newState.tourTodos = [...newData.tourTodos];
      }
      if (newData.tourTasks) {
        newState.tourTasks = structuredClone(newData.tourTasks);
      }
      setSelectedOrderData(newState.tourOrders[selectedOrderUUID]);
      refetchOrderTasks();
      refetchOrderTodos();
      refetchTourTasks();
      refetchTourTodos();
      return newState;
    });
  }

  function updateLocalNewOrderData(
    newOrderData: LocalNewOrderDataSnapshot
  ): void {
    if (!newOrderData) {
      console.error(
        `Error in updateLocalNewOrderData()! Undefined newOrderData!`
      );
      return;
    }
    const newOrderDataCopy: OrderType = structuredClone(newOrderData.order);

    setLocalNewOrderData({
      order: newOrderDataCopy,
    });
    setSelectedOrderData(newOrderDataCopy);
  }

  function updateSelectedOrderUUID(newUUID: UUIDType) {
    if (!localExistingOrderData.tourOrders[newUUID]) {
      console.error(
        `Error in updateSelectedOrderUUID()! Order with uuid of ${newUUID} was not found!`
      );
      return;
    }
    setSelectedOrderUUID(newUUID);
    setSelectedOrderData(localExistingOrderData.tourOrders[newUUID]);
  }

  function refetchTourDataAndOrders(): void {
    if (refetchOrderData) {
      refetchOrderData();
    }
    if (refetchTourData) {
      refetchTourData();
    }
    if (refetchTourOrders) {
      refetchTourOrders();
    }
  }

  return (
    <TourOrdersFullDataContext.Provider
      value={{
        contextType,
        localExistingOrderData,
        localNewOrderData,
        selectedOrderUUID,
        updateSelectedOrderUUID,
        selectedOrderData,
        updateLocalExistingOrderData,
        updateLocalNewOrderData,
        refetchTourDataAndOrders,
        tourOrdersUUIDS: rearrangedTourOrdersUUIDs,
      }}
    >
      {children}
    </TourOrdersFullDataContext.Provider>
  );
};

export const useTourOrdersFullData = () => {
  const context: TourOrdersFullDataContextType = useContext(
    TourOrdersFullDataContext
  );

  if (!context) {
    throw new Error(
      'useTourOrdersFullDataContext hook must be used within a TourOrdersFullDataProvider'
    );
  }

  return context;
};
