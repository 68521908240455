import React, { FC } from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { CarrierType } from '@types';
import { CarrierDisplay } from './CarrierDisplay';
import { CarrierForm } from './CarrierForm';

type Props = {};

export const CarrierSlot: FC<Props> = () => {
  const { localExistingOrderData } = useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const carrier: CarrierType | null = tourData?.carrier || null;

  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {carrier ? <CarrierDisplay /> : <CarrierForm />}
      </CardContent>
    </Card>
  );
};
