import { useWorkspaces } from '@dizzbo/core/hooks';
import {
  Box,
  Button,
  ChevronDownIcon,
  Chip,
  Menu,
  TruckFTLIcon,
  TruckIcon,
  TruckLTLIcon,
  Typography,
} from '@dizzbo/ui';
import { SxProps, useTheme } from '@mui/material';
import { FilterType } from '@types';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterMenuList } from '../FilterMenuList';
import { LoadTypeFilterConfig } from './LoadTypeFilterConfig';

export const LoadTypeFilter: FC = () => {
  const filterIconStyles: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3px',
    padding: '0 6px',
  };

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'loadTypeFilter',
  });

  const theme = useTheme();
  const { t } = useTranslation();
  const { activeWorkspace } = useWorkspaces();

  const loadTypeFilterValues: Array<FilterType> =
    activeWorkspace?.settings?.filters?.loadType || [];

  const hasActiveFilters: boolean = loadTypeFilterValues.length > 0;

  let loadType: string | undefined;
  if (hasActiveFilters && loadTypeFilterValues.length === 1) {
    loadType = loadTypeFilterValues[0].name;
  }

  const getLoadTypeFilterLabel: FC = (loadType: string) => {
    switch (loadType) {
      case 'FTL':
        return (
          <Box sx={{ ...filterIconStyles }}>
            <TruckFTLIcon />
            FTL
          </Box>
        );
      case 'LTL':
        return (
          <Box sx={{ ...filterIconStyles }}>
            <TruckLTLIcon />
            LTL
          </Box>
        );
      default:
        return (
          <Box sx={{ ...filterIconStyles }}>
            <TruckIcon />
            {t('all')}
          </Box>
        );
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        sx={{
          boxShadow: hasActiveFilters
            ? `inset 0px 0px 0px 2px ${theme.styles.button.primary.focus.boxShadowColor};`
            : 'none',
        }}
        endIcon={
          <>
            <Chip
              label={getLoadTypeFilterLabel(loadType)}
              size="small"
              variant="oxford-40"
            />
            <ChevronDownIcon />
          </>
        }
        {...bindTrigger(popupState)}
      >
        <Typography sx={{ fontWeight: 500 }}>FTL/LTL</Typography>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        <FilterMenuList
          filterType="loadType"
          filterConfig={LoadTypeFilterConfig}
          initialFilterValues={loadTypeFilterValues}
        />
      </Menu>
    </>
  );
};
