import { styled, useTheme } from '@mui/material/styles';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import React, { FC } from 'react';

import { AvatarListItem, Card, EditIcon, IconButton, Stack } from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { UserType } from '@types';
import { EditAssigneesDialog } from './EditAssigneesDialog';
const AssigneeListItemWrapperStyles = styled(Card)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: 56,
  paddingTop: 12,
  paddingBottom: 12,
  alignItems: 'center',
}));

type Props = {};

export const AssigneeListItem: FC<Props> = () => {
  const theme = useTheme();
  const { selectedOrderData, selectedOrderUUID } = useTourOrdersFullData();
  const assignee: UserType | null = selectedOrderData?.assignee || null;

  return (
    <AssigneeListItemWrapperStyles>
      <AvatarListItem
        username={assignee?.displayName}
        avatarSx={{
          width: 32,
          height: 32,
          ...theme.typography.bodyBoldSmall,
        }}
      />
      <Stack sx={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="participant-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <EditIcon />
              </IconButton>
              <EditAssigneesDialog
                key={`key_assignee_order_${selectedOrderUUID}_${assignee}`}
                orderUUID={selectedOrderUUID}
                popupState={popupState}
                assignees={[assignee]}
              />
            </React.Fragment>
          )}
        </PopupState>
      </Stack>
    </AssigneeListItemWrapperStyles>
  );
};
