import { getOrderTasks } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { OrderTodosTasksType, UUIDType } from '@types';

type MultipleOrdersTasksData = {
  data: Record<UUIDType, OrderTodosTasksType>;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useGetMultipleOrdersTasks = (
  orderUUIDs: Array<UUIDType>
): MultipleOrdersTasksData => {
  const queryResults: Array<UseQueryResult<OrderTodosTasksType, Error>> =
    useQueries({
      queries: orderUUIDs.map((orderUUID) => ({
        queryKey: [QueryKeys.TASKS, orderUUID],
        queryFn: () => getOrderTasks(orderUUID),
        enabled: !!orderUUID,
      })),
    });

  const data: Record<UUIDType, OrderTodosTasksType> = queryResults.reduce(
    (acc, queryResult, index) => {
      const { data: orderTodosTasks } = queryResult;
      const orderUUID: UUIDType = orderUUIDs[index];
      acc[orderUUID] = orderTodosTasks;
      return acc;
    },
    {}
  );

  function refetch(): void {
    queryResults.forEach((queryResult) => {
      if (queryResult.refetch) {
        queryResult.refetch();
      }
    });
  }

  const isFetching: boolean = queryResults.some((query) => query.isFetching);
  const isError: boolean = queryResults.some((query) => query.isError);

  return {
    data,
    isFetching,
    isError,
    refetch,
  };
};
