import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PlusIcon } from '@dizzbo/ui';
import { CreateOrderDialog } from '@orders/components/CreateOrderDialog';

import { TourOrdersFullDataProvider } from '@order-detail/contexts';
import { useOrderDetail } from '../hooks';

type Props = {
  totalPrice: string;
};

export const CreateOrderButton: React.FC<Props> = ({ totalPrice }) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderCreateDialog',
  });
  const { orderData } = useOrderDetail();
  const newOrderData = {
    ...orderData,
    price: parseFloat(totalPrice),
  };
  if (!orderData) return null;

  return (
    <>
      <Button
        type="submit"
        size="large"
        startIcon={<PlusIcon />}
        onClick={() => popupState.open()}
        {...bindTrigger(popupState)}
      >
        {t('createNewOrder')}
      </Button>
      {newOrderData ? (
        <TourOrdersFullDataProvider
          contextType={'newOrder'}
          initialDisplayedOrderUUID={newOrderData.uuid}
          refetchOrderData={null}
          tourData={null}
          refetchTourData={null}
          tourOrders={null}
          refetchTourOrders={null}
          rearrangedTourOrdersUUIDs={null}
          newOrderData={{
            order: newOrderData,
          }}
        >
          <CreateOrderDialog orderData={newOrderData} popupState={popupState} />
        </TourOrdersFullDataProvider>
      ) : null}
    </>
  );
};
