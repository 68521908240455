export function hexToRgba(hex: string, opacity: number = 1): string {
  if (!/^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(hex)) {
    throw new Error(
      'Error in stylingUtils method hexToRgba! Invalid hex color code!'
    );
  }
  if (!(opacity >= 0 && opacity <= 1)) {
    throw new Error(
      'Error in stylingUtils method hexToRgba! Invalid opacity value!'
    );
  }

  hex = hex.replace('#', '');

  // If it's a 3-digit hex code, convert it to 6 digits
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
