import { TruckFTLIcon, TruckLTLIcon } from '@dizzbo/ui';
import { FilterConfig } from '@types';
import React from 'react';

export const LoadTypeFilterConfig: FilterConfig = [
  {
    groupName: 'filters_filterLoadType',
    key: 'loadType',
    filters: [
      {
        name: 'FTL',
        value: 'FTL',
        icon: <TruckFTLIcon sx={{ width: 16, height: 16 }} />,
      },
      {
        name: 'LTL',
        value: 'LTL',
        icon: <TruckLTLIcon sx={{ width: 16, height: 16 }} />,
      },
    ],
  },
];
