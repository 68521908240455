import { LocalisationLanguages, Translations } from '../types';
import { translationsDE } from './de';
import { translationsEN } from './en';
import { translationsES } from './es';
import { translationsIT } from './it';

export const translations: Record<LocalisationLanguages, Translations> = {
  en: {
    ...translationsEN,
  },
  de: {
    ...translationsDE,
  },
  it: {
    ...translationsIT,
  },
  es: {
    ...translationsES,
  },
};
