import Popper from '@mui/material/Popper';
import { SxProps } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { FC } from 'react';

import { updateOrderDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Autocomplete,
  CheckIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  LocationIcon,
  TextField,
  Typography,
} from '@dizzbo/ui';

import { useGetOrderStops } from '@core/hooks';
import { generateAxiosErrorMessage } from '@dizzbo/core/utils';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { LoadingPointType, StopType } from '@types';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type Props = {
  deliveryUUID: string;
  action: string;
  label: string;
  value?: StopType;
  sx?: SxProps;
  disablePopupIcon?: boolean;
  disableClearable?: boolean;
};

const PopperMy = function (props) {
  // need to override style here because else "fit-content" would not be
  // recognized by MUI and how it calculates the width of the popper
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const DeliveryLoadingPointAutocomplete: FC<Props> = ({
  deliveryUUID,
  action = 'loading',
  label,
  value,
  sx,
  disablePopupIcon,
  disableClearable = true,
}) => {
  const queryClient = useQueryClient();
  const { selectedOrderUUID } = useTourOrdersFullData();
  const { data: stopsData } = useGetOrderStops(selectedOrderUUID);

  const mutation = useMutation({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.DELIVERIES],
    mutationFn: (values) => {
      return updateOrderDelivery(selectedOrderUUID, deliveryUUID, values);
    },
    onSuccess: (data: any, values: any) => {},
    onError: (error: AxiosError) => {
      toast.error(generateAxiosErrorMessage(error));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.DELIVERIES],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.STOPS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.DELIVERIES, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.STOPS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TODOS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS, selectedOrderUUID],
      });
    },
  });

  const formatFullAddress = (loadingPoint: LoadingPointType) => {
    const shortNameString = loadingPoint.shortName
      ? loadingPoint.shortName + ', '
      : '';
    const nameString = loadingPoint.name ? loadingPoint.name + ', ' : '';
    return `${shortNameString}${nameString}${loadingPoint.address.formatted}`;
  };

  const { t } = useTranslation();

  return (
    <Autocomplete
      sx={{
        ...sx,
        ...(disablePopupIcon && {
          '.MuiInputBase-root': {
            paddingRight: '10px !important',
          },
        }),
      }}
      disableClearable={disableClearable}
      disableCloseOnSelect={true}
      autoComplete={true} // seems not to work
      autoHighlight
      blurOnSelect={true} // input should be blurred when an option is selected
      clearOnEscape={true} // clear input via pressing Esc
      popupIcon={<LocationIcon />}
      PopperComponent={PopperMy}
      componentsProps={{
        popper: {
          sx: {
            width: 'auto',
          },
        },
        popupIndicator: {
          sx: {
            ...(disablePopupIcon && {
              display: 'none',
            }),
          },
        },
      }}
      noOptionsText={t('noLoadingPointsFound')}
      options={stopsData || []}
      value={value}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : formatFullAddress(option.loadingPoint)
      }
      isOptionEqualToValue={(option, value) => option.uuid === value?.uuid}
      onChange={(event: any, value: StopType | null, reason: string) => {
        if (action === 'loading') {
          mutation.mutate({ loadingStop: value && value.uuid });
        }
        if (action === 'unloading') {
          mutation.mutate({
            unloadingStop: value && value.uuid,
          });
        }
      }}
      renderOption={(props, stop: StopType, { inputValue }) => {
        const addressString = formatFullAddress(stop.loadingPoint);
        const matches = match(addressString, inputValue, {
          insideWords: true,
        });
        const parts = parse(addressString, matches);

        // removing className property as we dont need the special styling applied via autocomplete
        const { className, ...rest } = props;

        let listItemProps = {};

        const text = parts.map((part, index) => (
          <Typography
            key={index}
            variant={part.highlight ? 'bodyBold' : 'bodyRegular'}
          >
            {part.text}
          </Typography>
        ));

        if (props['aria-selected']) {
          listItemProps = {
            secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
          };
        }

        return (
          <ListItem key={stop.uuid} {...rest} {...listItemProps}>
            <ListItemIcon>
              <LocationIcon sx={{ width: 16, height: 16 }} />
            </ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </ListItem>
        );
      }}
    />
  );
};
