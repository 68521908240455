import { getOrderAmounts } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderAmounts, UUIDType } from '@types';

export const useGetOrderAmounts = (
  orderUUID: UUIDType
): UseQueryResult<OrderAmounts, Error> => {
  return useQuery({
    queryKey: [QueryKeys.AMOUNTS, orderUUID],
    queryFn: () => getOrderAmounts(orderUUID),
    enabled: !!orderUUID,
  });
};
