/* eslint-disable camelcase */
import { useWorkspaces } from '@dizzbo/core/hooks';
import React from 'react';

import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import {
  Button,
  ChevronDownIcon,
  Divider,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  ResetIcon,
  SettingsIcon,
} from '@dizzbo/ui';
import { useTranslation } from 'react-i18next';
import { ColumnsSettingsItem } from './ColumnsSettingsItem';
import { defaultColumnOrder, defaultColumnVisibility } from './columns';

type Props = {};

export const ColumnsSettingsButton: React.FC<Props> = ({}) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const { activeWorkspace, activeWorkspaceUUID, setWorkspaceSettings } =
    useWorkspaces();

  let columnOrder: Array<string> =
    activeWorkspace?.settings?.tableSettings?.columnOrder;

  if (!columnOrder || columnOrder.length === 0) {
    columnOrder = defaultColumnOrder;
  } else {
    defaultColumnOrder.forEach((id) => {
      if (!columnOrder.includes(id)) {
        columnOrder.push(id);
      }
    });
  }

  const columnVisibility =
    activeWorkspace?.settings?.tableSettings?.columnVisibility ||
    defaultColumnVisibility;

  const handleClick = (reset: boolean) => {
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      columnVisibility: defaultColumnVisibility,
    });
  };

  const handleChange = (columnName: string) => {
    const updatedColumnVisibility = {
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName],
    };
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      columnVisibility: updatedColumnVisibility,
    });
  };
  const sortedColumnVisibilityArray = columnOrder.map((value) => {
    return { key: value, value: columnVisibility[value] };
  });

  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<SettingsIcon />}
        endIcon={<ChevronDownIcon />}
        {...bindTrigger(popupState)}
      ></Button>

      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{
          dense: true,
        }}
      >
        <ListSubheader>{t('toggleColumns')}</ListSubheader>
        {sortedColumnVisibilityArray.map((column) => (
          <ColumnsSettingsItem
            key={column.key}
            columnName={column.key}
            visible={column.value}
            handleChange={handleChange}
          />
        ))}
        <Divider />
        <MenuItem
          disabled={columnVisibility === defaultColumnVisibility}
          onClick={() => handleClick(true)}
        >
          <ListItemIcon>
            <ResetIcon />
          </ListItemIcon>
          {t('reset')}
        </MenuItem>
      </Menu>
    </>
  );
};
