import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import React, { FC } from 'react';

import { CarrierPrice } from './CarrierPrice';
import { Contacts } from './Contacts';
import { Contributors } from './Contributors';
import { TourPlan } from './TourPlan';
import { TourSummary } from './TourSummary';
import { TransportContract, TransportContractList } from './TransportContract';
import { VehicleAsssignment } from './VehicleAsssignment';

interface Props {}

export const TourDetailForm: FC<Props> = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={6}
      rowSpacing={12}
      sx={{
        margin: 0,
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }}
    >
      <Grid xs={12}>
        <TourSummary />
      </Grid>
      <Grid xs={12} lg={6}>
        <VehicleAsssignment />
      </Grid>

      <Grid xs={6} lg={6}>
        <CarrierPrice />
      </Grid>
      <Grid xs={12}>
        <TourPlan />
      </Grid>
      <Grid xs={6} lg={6}>
        <Contacts />
      </Grid>
      <Grid xs={6} lg={6}>
        <TransportContract />
      </Grid>
      <Grid xs={12}>
        <Contributors />
      </Grid>
      <Grid xs={12}>
        <TransportContractList />
      </Grid>
    </Grid>
  );
};
