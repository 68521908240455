import { DateRange } from '@mui/x-date-pickers-pro';
import {
  DeliveryType,
  ISODateTimeType,
  ISODateType,
  OrderDetailStopDateFormData,
  OrderSubtodoType,
  OrderTodosTasksType,
  OrderTodoType,
  StopType,
  TourOrderType,
  UUIDType,
} from '@types';
import dayjs, { Dayjs } from 'dayjs';
import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import { assertNever } from './typeUtils';

type StopUUID = UUIDType;
type DeliveryIndex = number;
export type StopDeliveriesIndices = {
  loadingDeliveriesIndices: Array<DeliveryIndex>;
  unloadingDeliveriesIndices: Array<DeliveryIndex>;
};

export function parseOrderDetailStopDateFormData(
  formData: OrderDetailStopDateFormData
): Partial<StopType> {
  const { requestedDate, scheduledAt, arrivedAt, timeSlot } = formData;

  const requestedDateParsed: ISODateType | null =
    requestedDate && requestedDate.isValid()
      ? (requestedDate.format('YYYY-MM-DD') as ISODateType)
      : null;
  const scheduledAtParsed: ISODateTimeType | null =
    scheduledAt && scheduledAt.isValid()
      ? (scheduledAt.toISOString() as ISODateTimeType)
      : null;
  const arrivedAtParsed: ISODateTimeType | null =
    arrivedAt && arrivedAt.isValid()
      ? (arrivedAt.toISOString() as ISODateTimeType)
      : null;

  let timeSlotStartsAtDateAdjusted: Dayjs | null = null;
  let timeSlotEndsAtDateAdjusted: Dayjs | null = null;

  if (requestedDate && timeSlot) {
    timeSlotStartsAtDateAdjusted =
      timeSlot[0] && timeSlot[0].isValid()
        ? requestedDate
            .hour(timeSlot[0].hour())
            .minute(timeSlot[0].minute())
            .second(timeSlot[0].second())
        : null;

    timeSlotEndsAtDateAdjusted =
      timeSlot[1] &&
      timeSlot[1].isValid() &&
      (timeSlot[1].isSame(timeSlot[0]) || timeSlot[1].isAfter(timeSlot[0]))
        ? requestedDate
            .hour(timeSlot[1].hour())
            .minute(timeSlot[1].minute())
            .second(timeSlot[1].second())
        : timeSlotStartsAtDateAdjusted;
  }

  const timeSlotStartsAtParsed: ISODateTimeType | null =
    timeSlotStartsAtDateAdjusted
      ? (timeSlotStartsAtDateAdjusted.toISOString() as ISODateTimeType)
      : null;

  const timeSlotEndsAtParsed: ISODateTimeType | null =
    timeSlotEndsAtDateAdjusted
      ? (timeSlotEndsAtDateAdjusted.toISOString() as ISODateTimeType)
      : null;

  const parsedData: Partial<StopType> = {
    requestedDate: requestedDateParsed,
    scheduledAt: scheduledAtParsed,
    timeslotStartsAt: timeSlotStartsAtParsed,
    timeslotEndsAt: timeSlotEndsAtParsed,
    arrivedAt: arrivedAtParsed,
  };

  return parsedData;
}

export function parseTimeSlotDateRange(
  timeSlotDateRange: Array<Dayjs> | undefined
): DateRange<Dayjs | null> {
  const parsedTimeSlotDateRange: DateRange<Dayjs | null> = timeSlotDateRange
    ? [timeSlotDateRange[0] || null, timeSlotDateRange[1] || null]
    : [null, null];

  return parsedTimeSlotDateRange;
}

export function rearrangeTourOrders(
  orders: Array<TourOrderType>,
  priorityUUID: UUIDType
): Array<TourOrderType> {
  const orderUUIDArr: Array<UUIDType> = orders.map(
    (order: TourOrderType) => order.uuid
  );

  const priorityOrderOldIndex: number = orderUUIDArr.indexOf(priorityUUID);
  if (priorityOrderOldIndex === -1) {
    return orders;
  }

  const ordersDataCopy: Array<TourOrderType> = [...orders];
  return [
    ...ordersDataCopy.splice(priorityOrderOldIndex, 1),
    ...ordersDataCopy,
  ];
}

export function rearrangeOrderUUIDS(
  orderUUIDArr: Array<UUIDType>,
  priorityUUID: UUIDType
): Array<UUIDType> {
  const priorityOrderOldIndex: number = orderUUIDArr.indexOf(priorityUUID);
  if (priorityOrderOldIndex === -1) {
    return orderUUIDArr;
  }

  const orderUUIDArrCopy: Array<UUIDType> = [...orderUUIDArr];
  return [
    ...orderUUIDArrCopy.splice(priorityOrderOldIndex, 1),
    ...orderUUIDArrCopy,
  ];
}

export function convertCamelCaseKeysToSnakeCase<T extends Record<string, any>>(
  obj: T
): T {
  const isCamelCase = (key: string) => /[A-Z]/.test(key);

  const toSnakeCase = (key: string) =>
    key.replace(/([A-Z])/g, '_$1').toLowerCase();

  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const newKey = isCamelCase(key) ? toSnakeCase(key) : key;
    result[newKey] = obj[key];
  });

  return result as T;
}

export function generateTodoPopupStates(
  reference: string
): Record<OrderTodoType, PopupState> {
  const popupStateKeys: Array<string> = [
    'order_confirmation_todo',
    'transport_contract_todo',
    'loading_time_todo',
    'unloading_time_todo',
    'pod_upload_todo',
    'pod_review_todo',
    'order_review_todo',
    'tour_review_todo',
  ];

  const popupStates: Record<OrderTodoType, PopupState> = popupStateKeys.reduce(
    (acc: Record<string, PopupState>, key: string) => {
      const state = usePopupState({
        variant: 'dialog',
        popupId: `todo_popup_${reference}_${key}`,
      });
      acc[key] = state;
      return acc;
    },
    {}
  );

  return popupStates;
}

export function generateOrderAllTasksArray(
  orderTasks: OrderTodosTasksType | undefined,
  isOrderTasksPending: boolean
): Array<OrderSubtodoType> {
  const allOrderTasks: Array<OrderSubtodoType> = !isOrderTasksPending
    ? Object.values(orderTasks).reduce(
        (acc: Array<OrderSubtodoType>, orderTask: Array<OrderSubtodoType>) => {
          acc.push(...orderTask);
          return acc;
        },
        []
      )
    : [];

  return allOrderTasks;
}

export function formatDuration(
  duration: number,
  format: 'dhm' | 'hm' | 'm'
): string {
  switch (format) {
    case 'dhm':
      return dayjs.duration(duration, 'seconds').format('D [d] H [h] m [min]');
    case 'hm':
      return dayjs.duration(duration, 'seconds').format('H [h] m [min]');
    case 'm':
      return dayjs.duration(duration, 'seconds').format('m [min]');
    default:
      assertNever(format);
  }
}

export function parseDeliveriesData(
  data: Record<string, Array<DeliveryType>>
): Record<StopUUID, StopDeliveriesIndices> {
  const parsedData: Record<StopUUID, StopDeliveriesIndices> = {};

  Object.values(data).forEach((orderDeliveries: Array<DeliveryType>) => {
    orderDeliveries.forEach((delivery: DeliveryType) => {
      const { index: deliveryIndex } = delivery;
      if (delivery.loadingStop) {
        const { uuid: loadingStopUUID } = delivery.loadingStop;

        if (!parsedData[loadingStopUUID]) {
          parsedData[loadingStopUUID] = {
            loadingDeliveriesIndices: [],
            unloadingDeliveriesIndices: [],
          };
        }
        parsedData[loadingStopUUID].loadingDeliveriesIndices.push(
          deliveryIndex
        );
      }

      if (delivery.unloadingStop) {
        const { uuid: unloadingStopUUID } = delivery.unloadingStop;

        if (!parsedData[unloadingStopUUID]) {
          parsedData[unloadingStopUUID] = {
            loadingDeliveriesIndices: [],
            unloadingDeliveriesIndices: [],
          };
        }
        parsedData[unloadingStopUUID].unloadingDeliveriesIndices.push(
          deliveryIndex
        );
      }
    });
  });

  return parsedData;
}
