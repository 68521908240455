export function mergeObjects(
  obj1: Record<string, string>,
  obj2: Record<string, string>
): Record<string, string> {
  for (const key in obj2) {
    if (key in obj1) {
      throw new Error(
        `Error in mergeObjects() in objectUtils.ts! Duplicate key found: ${key}`
      );
    }
  }

  return Object.assign({}, obj1, obj2);
}
