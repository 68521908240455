import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { updateTour } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  WarningIcon,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { TourType } from '@types';
import { CarrierPriceTotal } from './CarrierPriceTotal';

type Props = {
  orientation?: string;
};

export const CarrierPrice: FC<Props> = ({ orientation = 'vertical' }) => {
  const queryClient = useQueryClient();
  const { localExistingOrderData, updateLocalExistingOrderData } =
    useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;

  const price: number = tourData?.price || null;

  const {
    mutate: mutateOrder,
    isError,
    error,
  }: any = useMutation<
    TourType,
    unknown,
    Partial<TourType>,
    { newTourData: TourType; previousTourData: TourType }
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) => updateTour({ tourUUID, tourData: values }),
    onMutate: async (tourData: Partial<TourType>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });

      // Snapshot the previous value
      const previousTourData = queryClient.getQueryData<TourType>([
        QueryKeys.TOURS,
        tourUUID,
      ]);

      const price = {
        price: Number(tourData.price),
      };

      const newTourData = { ...previousTourData, ...price };

      // Optimistically update to the new value
      queryClient.setQueryData([QueryKeys.TOURS, tourUUID], newTourData);

      // Return the new context
      return { previousTourData, newTourData };
    },
    onError: (err, newOrder, context) => {
      queryClient.setQueryData(
        [QueryKeys.TOURS, tourUUID],
        context.previousTourData
      );
    },
    // Always refetch after error or success:
    onSettled: (updatedTourData: TourType) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      tourData.price = updatedTourData.price;
      tourData.profit = updatedTourData.profit;
      updateLocalExistingOrderData({
        tourData,
      });
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      price: price ? price.toFixed(2) : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData) => {
    mutateOrder(formData);
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title="Carrier Price" variant="small" />
      <CardContent>
        {!price && (
          <Alert
            title="Carrier price missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Controller
            name="price"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <TextField
                label="Carrier Price"
                required
                type="number"
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <CardContent>
        {/* TODO Price Positions */}
        {orientation === 'vertical' && <Box sx={{ height: 80 }}></Box>}
      </CardContent>
      <CardContent>
        <CarrierPriceTotal price={+getValues('price')} />
      </CardContent>
    </Card>
  );
};
