import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { TourType, UserType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { ParticipantsAutoCompleteTextField } from './ParticipantsAutoCompleteTextField';

type Props = {
  tourUUID: UUIDType;
  popupState: PopupState;
  participants: UserType[];
};

export const AddParticipantsDialog: React.FC<Props> = ({
  tourUUID,
  popupState,
  participants,
}: Props) => {
  const { localExistingOrderData, updateLocalExistingOrderData } =
    useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    // reset the form if the dialog is not open.
    // Because this Dialog doesn't unmount.
    if (popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  const { mutate: mutateTour, isPending }: any = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID, QueryKeys.PARTICIPANTS],
    mutationFn: (values) => updateTour({ tourUUID, tourData: values }),
  });

  const onSubmit = (formData: any) => {
    const oldUserUUIDs: Array<string> =
      participants && participants.length > 0
        ? participants.map((user: UserType) => user.uuid)
        : [];

    const newParticipants: Array<UserType> = formData?.participants;

    const newUserUUIDs: Array<string> =
      newParticipants && newParticipants.length > 0
        ? newParticipants.map((user: UserType) => user.uuid)
        : [];

    const updatedParticipants: Array<string> = [
      ...newUserUUIDs,
      ...oldUserUUIDs,
    ];

    mutateTour(
      { participants: updatedParticipants },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          toast.success(t('participantsAdded'));
          tourData.participants.push(...formData.participants);
          updateLocalExistingOrderData({
            tourOrders: localExistingOrderData.tourOrders,
          });
          popupState.close();
        },
        onError: () => {
          toast.error(t('participantsAddError'));
          popupState.close();
        },
      }
    );
  };

  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>Add Participants</DialogTitle>
      <DialogContent dividers>
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <Controller
              name="participants"
              control={control}
              defaultValue={[]}
              rules={{ required: false }}
              render={({ field }) => (
                <ParticipantsAutoCompleteTextField
                  label={t('participants')}
                  {...field}
                />
              )}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isPending}
            type="submit"
            form="form"
            disabled={!isDirty || !isValid}
          >
            {t('apply')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
