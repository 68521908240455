import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { getOrderProofOfDelivery } from '@core/api';
import { QueryKeys } from '@core/config';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { OrderSubtodoType, OrderType } from '@types';
import { ProofOfDeliveryReviewDialog } from '../../OrderDetailForm/ProofOfDeliveryDocuments/ProofOfDeliveryReviewDialog';

type Props = {
  orderData: OrderType;
  popupState: PopupState;
  subTodos: OrderSubtodoType[];
};

export const PoDReviewDialog: React.FC<Props> = ({
  orderData,
  popupState,
  subTodos,
}: Props) => {
  const { uuid: orderUUID } = orderData;
  const queryClient = useQueryClient();
  const {
    refetchTourDataAndOrders,
    localExistingOrderData,
    updateLocalExistingOrderData,
  } = useTourOrdersFullData();

  const podReviewSubTodoUUID: string | undefined =
    subTodos.length > 0
      ? subTodos.find(
          (subTodo: OrderSubtodoType) =>
            subTodo.type === 'transports.proofofdelivery'
        )?.uuid
      : undefined;

  const { data: podData, isPending: isPendingPoD } = useQuery({
    queryKey: [QueryKeys.ORDERS, podReviewSubTodoUUID],
    queryFn: () => getOrderProofOfDelivery(orderUUID, podReviewSubTodoUUID),
    enabled: !!podReviewSubTodoUUID,
  });

  if (isPendingPoD || !podData) return null;

  const handleSuccess = (): void => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ORDERS, orderUUID],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TASKS, orderUUID],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TODOS, orderUUID],
    });
    refetchTourDataAndOrders();
  };

  const { uuid: podUUID, file, createdAt, title } = podData;

  return (
    <ProofOfDeliveryReviewDialog
      podUUID={podUUID}
      orderUUID={orderUUID}
      popupState={popupState}
      file={file}
      fileTitle={title}
      fileDate={createdAt}
      onSuccess={handleSuccess}
    />
  );
};
