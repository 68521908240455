import React, { FC, useState } from 'react';

import { Card } from '@dizzbo/ui';

import { useGetOrderDeliveries } from '@core/hooks';
import { useIsLoadedOnce } from '@dizzbo/core/hooks';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { DeliveryDetail } from './DeliveryDetail';
import { LoadingPlanTabPanel } from './LoadingPlanTabPanel';
import { LoadingPlanTabs } from './LoadingPlanTabs';
import { Stops } from './Stops';

type Props = {};

export const LoadingPlan: FC<Props> = () => {
  const { selectedOrderUUID } = useTourOrdersFullData();
  const [activeTab, setActiveTab] = useState<number>(0);

  const { data: deliveriesData, isFetching } =
    useGetOrderDeliveries(selectedOrderUUID);

  const { isLoadedOnce } = useIsLoadedOnce(isFetching, !!deliveriesData);

  return isLoadedOnce ? (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      <LoadingPlanTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        deliveriesData={deliveriesData}
      />
      <LoadingPlanTabPanel value={activeTab} index={0}>
        <Stops />
      </LoadingPlanTabPanel>
      {deliveriesData?.map((delivery, index) => (
        <LoadingPlanTabPanel
          key={`key_delivery_${delivery.uuid}_${index}`}
          value={activeTab}
          index={index + 1}
        >
          <DeliveryDetail setActiveTab={setActiveTab} deliveryData={delivery} />
        </LoadingPlanTabPanel>
      ))}
    </Card>
  ) : null;
};
