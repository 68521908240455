import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  WarningIcon,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { OrderType, TourType, UUIDType } from '@types';
import { CustomerPriceTotal } from './CustomerPriceTotal';

type Props = {
  orientation?: string;
};

export const CustomerPrice: React.FC<Props> = ({
  orientation = 'vertical',
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    selectedOrderUUID,
    selectedOrderData,
    contextType,
    localExistingOrderData,
    updateLocalExistingOrderData,
    updateLocalNewOrderData,
  } = useTourOrdersFullData();

  const price: number = selectedOrderData?.price || null;

  const {
    mutate: mutateOrder,
    isError,
    error,
  }: any = useMutation<
    OrderType,
    unknown,
    OrderType,
    { newOrder: OrderType; previousOrder: OrderType }
  >({
    mutationKey: [QueryKeys.ORDERS, selectedOrderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: selectedOrderUUID, orderData: values }),
    onMutate: async (orderData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID],
      });

      // Snapshot the previous value
      const previousOrder = queryClient.getQueryData<OrderType>([
        QueryKeys.ORDERS,
        selectedOrderUUID,
      ]);

      const price = {
        price: Number(orderData.price),
      };

      const newOrder = { ...previousOrder, ...price };
      // Optimistically update to the new value
      queryClient.setQueryData([QueryKeys.ORDERS, selectedOrderUUID], newOrder);

      // Return a context with the previous and new todo
      return { previousOrder, newOrder };
    },
    onError: (err, newOrder, context) => {
      queryClient.setQueryData(
        [QueryKeys.ORDERS, selectedOrderUUID],
        context.previousOrder
      );
    },
    // Always refetch after error or success:
    onSettled: async (updatedOrderData: OrderType) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID],
      });

      if (contextType === 'existingOrder') {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TOURS, selectedOrderData.tour.uuid],
        });

        const tourData: TourType = await queryClient.fetchQuery({
          queryKey: [QueryKeys.TOURS, selectedOrderData.tour.uuid],
        });

        const tourOrders: Record<UUIDType, OrderType> =
          localExistingOrderData.tourOrders;
        tourOrders[selectedOrderUUID].price = updatedOrderData.price;
        updateLocalExistingOrderData({
          tourOrders,
          tourData,
        });
      } else {
        selectedOrderData.price = updatedOrderData.price;
        updateLocalNewOrderData({
          order: selectedOrderData,
        });
      }
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      price: price ? price.toFixed(2) : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData) => {
    mutateOrder(formData);
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title={t('customerPrice')} variant="small" />
      <CardContent>
        {!price && (
          <Alert
            title={t('customerPriceMissing')}
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Controller
            name="price"
            control={control}
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <TextField
                label={t('customerPrice')}
                required
                type="number"
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                // display={isFieldLocked('price')}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <CardContent>
        {/* TODO Price Positions */}
        {orientation === 'vertical' && <Box sx={{ height: 80 }}></Box>}
      </CardContent>
      <CardContent>
        <CustomerPriceTotal price={getValues('price')} />
      </CardContent>
    </Card>
  );
};
