import { Box } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { OrderTodosTasksType, OrderTodoType, UUIDType } from '@types';
import React, { FC } from 'react';
import { TodoElement } from './TodoElement';

type Props = {};

export const TodosSection: FC<Props> = () => {
  const { localExistingOrderData } = useTourOrdersFullData();
  const {
    tourData,
    rearrangedTourOrdersUUIDs,
    tourOrdersTasks,
    tourOrdersTodos,
  } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;

  const todosSectionStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 24px',
    overflowY: 'auto',
  };

  return (
    <Box sx={{ ...todosSectionStyles }}>
      <TodoElement type={'tour'} uuid={tourUUID} />
      {rearrangedTourOrdersUUIDs.map((orderUUID: UUIDType, index: number) => {
        const tasks: OrderTodosTasksType = tourOrdersTasks[orderUUID] || null;
        const todos: Array<OrderTodoType> = tourOrdersTodos[orderUUID] || null;

        return tasks && todos ? (
          <TodoElement
            type={'order'}
            uuid={orderUUID}
            key={`order_${orderUUID}_${index}_todo_element`}
          />
        ) : null;
      })}
    </Box>
  );
};
