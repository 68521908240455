import { useTranslation } from 'react-i18next';

/**
 * Provides similar functionality to the useTranslation hook, but is ignored by the string extractor script.
 * The string translations should be manually tracked, added, and removed in untrackedTranslation.json files in the locale.
 * The keys for both tracked and untracked strings should be unique and not overlap.
 *
 * Example:
 * const { lt } = useLocalTranslation();
 * lt('keyGoesHere');
 *
 * @return { lt } lt - Function that takes a string and returns the translated string
 */
export const useLocalTranslation = () => {
  const { t } = useTranslation();

  const lt = (string: string) => t(string);

  return { lt };
};
