import { useWorkspaces } from '@dizzbo/core/hooks';
import React, {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type SearchContextType = {
  searchValue: string;
  setSearchValue: (newSearchValue: string) => void;
};

const SearchContext: Context<SearchContextType> = createContext<
  SearchContextType | undefined
>(undefined);

export const SearchValueProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activeWorkspace, activeWorkspaceUUID, isLoadingWorkspaces } =
    useWorkspaces();

  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!isLoadingWorkspaces) {
      setSearchValue(activeWorkspace?.settings?.filters?.search || '');
    }
  }, [activeWorkspaceUUID, isLoadingWorkspaces]);

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchValue = () => {
  const context: SearchContextType = useContext(SearchContext);

  if (!context) {
    throw new Error(
      'useSearchValue hook must be used within a SearchValueProvider'
    );
  }

  return context;
};
