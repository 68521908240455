import { useLocalTranslation } from '@dizzbo/core';
import {
  Box,
  Cell,
  Tooltip,
  TruckFTLIcon,
  TruckLTLIcon,
  Typography,
} from '@dizzbo/ui';
import { green, oxford } from '@dizzbo/ui/theme/colors';
import { SxProps } from '@mui/material';
import { useGetOrderAmounts } from '@orders/queries';
import { OrderAmounts, OrderType } from '@types';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  cell: Cell<OrderType>;
};

export const LoadTypeCell: FC<Props> = ({
  cell: {
    row: { original },
  },
}) => {
  const { t } = useTranslation();
  const { lt } = useLocalTranslation();
  const { loadType, uuid } = original;
  const [orderAmounts, setOrderAmounts] = useState<OrderAmounts | undefined>(
    undefined
  );

  const { refetch } = useGetOrderAmounts(uuid);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function loadTourAmounts(): Promise<void> {
    try {
      setIsLoading(true);
      const { data } = await refetch();
      setOrderAmounts(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(
        `Something went wrong when searching for tour amounts! Error : ${error?.response || error.message}`
      );
    }
  }

  const boxStylesLoadSpecific: SxProps =
    loadType === 'LTL'
      ? {
          bgcolor: green[100],
          border: 'none',
        }
      : {
          bgcolor: 'transparent',
          border: `1px solid ${oxford[40]}`,
        };

  const boxStylesGeneric: SxProps = {
    display: 'flex',
    gap: '4px',
    borderRadius: '12px',
    padding: '8px',
    width: '60px',
    height: '25px',
    color: oxford[40],
    userSelect: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const textStyles: SxProps = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  };

  const tooltipStyles: SxProps = {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '4px',
  };

  const tooltipLabelStyles: SxProps = {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    color: oxford[15],
  };

  const tooltipValueStyles: SxProps = {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
  };

  const getTourAmounts = (): ReactElement => {
    if (!orderAmounts || isLoading) {
      return (
        <Box>
          <Typography>{isLoading ? t('loading') : t('dataMissing')}</Typography>
        </Box>
      );
    }
    const { tourAmounts } = orderAmounts;
    const { weight: tourWeight, goods } = tourAmounts;
    return (
      <>
        <Typography sx={{ ...tooltipLabelStyles }}>
          {loadType === 'LTL' ? t('tourWeight') : t('weight')}
        </Typography>
        <Typography sx={{ ...tooltipValueStyles }}>
          {tourWeight.toFixed(0)} kg
        </Typography>
        <br />
        <Typography sx={{ ...tooltipLabelStyles }}>
          {loadType === 'LTL' ? t('tourUnits') : t('units')}
        </Typography>
        {Object.keys(goods).map((key: string) => {
          const value: number = goods[key];

          return (
            <Typography
              key={`tour-good-amount-${key}`}
              sx={{ ...tooltipValueStyles }}
            >
              {value} {lt(`goods_unit_type_${key}`)}
            </Typography>
          );
        })}
      </>
    );
  };

  return (
    <Tooltip
      title={<Box sx={{ ...tooltipStyles }}>{getTourAmounts()}</Box>}
      placement={'bottom'}
      onMouseEnter={() => loadTourAmounts()}
    >
      <Box sx={{ ...boxStylesGeneric, ...boxStylesLoadSpecific }}>
        {loadType === 'LTL' ? (
          <TruckLTLIcon sx={{ height: 'auto' }} />
        ) : (
          <TruckFTLIcon sx={{ height: 'auto' }} />
        )}
        <Typography sx={{ ...textStyles }}>{loadType}</Typography>
      </Box>
    </Tooltip>
  );
};
