import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getTourTodos } from '@core/api';
import { QueryKeys } from '@core/config';
import { TourTodoType, UUIDType } from '@types';

export const useGetTourTodos = (tourUUID: UUIDType) => {
  const queryResult: UseQueryResult<Array<TourTodoType>, Error> = useQuery({
    queryKey: [QueryKeys.TOUR_TODOS, tourUUID],
    queryFn: () => getTourTodos(tourUUID),
    enabled: !!tourUUID,
  });

  return queryResult;
};
