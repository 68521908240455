import { SxProps, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cancelOrder, copyOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { useWorkspaces } from '@dizzbo/core/hooks';
import {
  ArrowRightIcon,
  Button,
  ErrorIcon,
  EyeIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MinusIcon,
  MoreVerticalIcon,
  PlusIcon,
} from '@dizzbo/ui';
import { useSearchValue } from '@orders/context';
import { LoadType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  orderUUID: UUIDType;
  tourReference: string;
  loadType: LoadType;
  removeOrderPopupState: PopupStateType;
  moveOrderPopupState: PopupStateType;
  addAnotherOrderPopupState: PopupStateType;
};

export const OrderActionsButton: FC<Props> = ({
  orderUUID,
  tourReference,
  loadType,
  removeOrderPopupState,
  moveOrderPopupState,
  addAnotherOrderPopupState,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { activeWorkspaceUUID, setWorkspaceSettings } = useWorkspaces();
  const { searchValue, setSearchValue } = useSearchValue();

  const { mutateAsync: mutateCopyOrder } = useMutation({
    mutationFn: () => copyOrder(orderUUID),
  });

  const { mutateAsync: mutateCancelOrder }: any = useMutation({
    mutationFn: () => cancelOrder(orderUUID),
  });

  const { t } = useTranslation();

  async function handleCopy(popupState: PopupStateType): Promise<void> {
    const id = toast.loading(t('copyingOrder'));
    try {
      const data = await mutateCopyOrder();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
      toast.update(id, {
        render: (
          <>
            {t('orderSuccessfullyCopied.')}
            <Button
              component={RouterLink}
              to={`/orders/${data.uuid}`}
              sx={{ marginLeft: 8 }}
              variant="secondary"
              size="medium"
            >
              {t('openCopy')}
            </Button>
          </>
        ),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        render: t('theOrderCouldNotBeCopied'),
        autoClose: 6000,
        type: 'error',
        isLoading: false,
      });
    }

    popupState.close();
  }

  async function handleCancel(popupState): Promise<void> {
    const id = toast.loading(t('cancellingOrder'));
    try {
      await mutateCancelOrder();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
      toast.update(id, {
        render: t('orderSuccessfullyCanceled'),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        render: t('theOrderCouldNotBeCanceled'),
        autoClose: 6000,
        type: 'error',
        isLoading: false,
      });
    }

    popupState.close();
  }

  async function handleShowTourOrders(
    popupState: PopupStateType
  ): Promise<void> {
    setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
      search: tourReference,
    });
    setSearchValue(tourReference);
    popupState.close();
  }

  function handleOpenRemoveOrderDialog(popupState: PopupStateType): void {
    removeOrderPopupState.open();
    popupState.close();
  }

  function handleOpenMoveOrderDialog(popupState: PopupStateType): void {
    moveOrderPopupState.open();
    popupState.close();
  }

  function handleOpenAddAnotherOrderDialog(popupState: PopupStateType): void {
    addAnotherOrderPopupState.open();
    popupState.close();
  }

  const menuItemStyles: SxProps = {
    alignItems: 'center',
    justifyContent: 'start',
  };

  const listItemStyles: SxProps = {
    display: 'flex',
    marginRight: '0',
    justifyContent: 'space-between',
    gap: '6px',
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => handleCopy(popupState)}
                sx={{ ...menuItemStyles }}
              >
                <ListItemIcon sx={{ ...listItemStyles }}>
                  <PlusIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('copyAndCreateNewOrder')}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleOpenMoveOrderDialog(popupState)}>
                <ListItemIcon>
                  <ArrowRightIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('moveThisOrderToAnotherTour')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => handleOpenAddAnotherOrderDialog(popupState)}
              >
                <ListItemIcon>
                  <ArrowRightIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('moveAnotherOrderToThisTour')}</ListItemText>
              </MenuItem>
              {loadType === 'LTL' && searchValue !== tourReference && (
                <MenuItem
                  onClick={() => handleShowTourOrders(popupState)}
                  sx={{ ...menuItemStyles }}
                >
                  <ListItemIcon sx={{ ...listItemStyles }}>
                    <EyeIcon sx={{ width: 16, height: 16 }} />
                    <ListItemText>{t('showTourOrders')}</ListItemText>
                  </ListItemIcon>
                </MenuItem>
              )}
              {loadType === 'LTL' && (
                <MenuItem
                  onClick={() => handleOpenRemoveOrderDialog(popupState)}
                >
                  <ListItemIcon>
                    <MinusIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>
                    {t('removeTheOrderFromTheCurrentTour')}
                  </ListItemText>
                </MenuItem>
              )}
              <MenuItem
                sx={{ ...menuItemStyles, color: theme.palette.error.main }}
                onClick={() => handleCancel(popupState)}
              >
                <ListItemIcon
                  sx={{ ...listItemStyles, color: theme.palette.error.main }}
                >
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('cancelOrder')}</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
};
