import { Box, Stack } from '@dizzbo/ui';
import { styled } from '@mui/material/styles';
import React from 'react';

import { DeliveryListItemType } from '@types';
import { DeliveryListItem } from './DeliveryListItem';

const ContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<any>(() => {
  return {
    maxWidth: '95%',
    overflowX: 'auto',
    paddingBottom: '8px',
  };
});

type Props = {
  loadingDeliveries: DeliveryListItemType[];
  unloadingDeliveries: DeliveryListItemType[];
};

export const DeliveryList: React.FC<Props> = ({
  loadingDeliveries = [],
  unloadingDeliveries = [],
}) => {
  return (
    <>
      <ContainerStyles>
        <Stack spacing={2} direction="row" mt={4} width={'100%'}>
          {loadingDeliveries.map((delivery, index) => (
            <DeliveryListItem
              key={delivery.uuid}
              index={delivery.index}
              action="loading"
            />
          ))}
          {unloadingDeliveries.map((delivery, index) => (
            <DeliveryListItem
              key={delivery.uuid}
              index={delivery.index}
              action="unloading"
            />
          ))}
        </Stack>
      </ContainerStyles>
    </>
  );
};
