import { useGetOrders } from '@orders/queries';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { OrderType, PaginatedListType } from '@types';
import React, {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
} from 'react';

export type OrderListContextType = {
  data: PaginatedListType<OrderType>;
  isPending: boolean;
  isStale: boolean;
  isFetching: boolean;
  isError: boolean;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<PaginatedListType<OrderType>>>;
};

const OrderListDataContext: Context<OrderListContextType> = createContext<
  OrderListContextType | undefined
>(undefined);

export const OrderListDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data, isPending, isStale, isFetching, isError, refetch } =
    useGetOrders();

  return (
    <OrderListDataContext.Provider
      value={{ data, isPending, isStale, isFetching, isError, refetch }}
    >
      {children}
    </OrderListDataContext.Provider>
  );
};

export const useOrderListDataContext = () => {
  return useContext(OrderListDataContext);
};
