import { reviewTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { useTourOrdersFullData } from '@order-detail/contexts';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(({ theme }) => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  popupState: PopupState;
};

export const TourReviewDialog: FC<Props> = ({ popupState }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    refetchTourDataAndOrders,
    localExistingOrderData,
    updateLocalExistingOrderData,
    selectedOrderUUID,
  } = useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;
  const reference: string = tourData.reference || '';
  const status: string = tourData.status || 'DRAFT';

  const { mutate: mutateReviewOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) => reviewTour(tourUUID),
    onSuccess() {
      toast.success(t('tourMarkedAsReviewed'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TODOS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      tourData.status = 'REVIEWED';
      updateLocalExistingOrderData({
        tourData,
      });
      refetchTourDataAndOrders();
      popupState.close();
    },
    onError() {
      toast.error(t('tourCouldNotBeMarkedAsReviewed'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateReviewOrder();
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('tourReview')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            <Typography variant="bodyRegular">
              {t('tourReference')}:&nbsp;{tourData.reference || ''}
            </Typography>
            <br />
            <Typography variant="bodyRegular">
              {t('price')}:&nbsp;{tourData.price}
            </Typography>
          </CardContent>
        </ContentCardStyles>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={handleClick}
            loading={isPending}
            startIcon={<SquareIcon />}
          >
            {t('markTourAsReviewed')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
