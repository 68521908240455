import React, { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useWorkspaces } from '@dizzbo/core/hooks';
import {
  Box,
  IconButton,
  InputAdornment,
  MinimalIconButton,
  RemoveIcon,
  SearchIcon,
  TextField,
} from '@dizzbo/ui';
import { useSearchValue } from '@orders/context';
import { debounce, DebouncedFunc } from 'lodash';

export const Search: FC = () => {
  const { t } = useTranslation();

  const { activeWorkspaceUUID, setWorkspaceSettings } = useWorkspaces();
  const { searchValue, setSearchValue } = useSearchValue();

  const debouncedSetWorkspaceSettings: DebouncedFunc<
    (searchString: string) => void
  > = useCallback(
    debounce((searchString: string) => {
      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
        search: searchString,
      });
    }, 1000),
    []
  );

  const searchChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setSearchValue(value);
    debouncedSetWorkspaceSettings(value);
  };

  const searchClearHandler = (): void => {
    setSearchValue('');
    setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
      search: '',
    });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
      }}
    >
      <TextField
        label={t('search')}
        value={searchValue}
        onChange={searchChangeHandler}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {searchValue && (
                <InputAdornment position="end">
                  <MinimalIconButton
                    sx={{ opacity: 0.5 }}
                    onClick={searchClearHandler}
                  >
                    <RemoveIcon />
                  </MinimalIconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </Box>
  );
};
