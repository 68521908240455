import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';
dayjs.extend(duration);

import { Currency, Distance } from '@dizzbo/core/i18n';
import { Skeleton, Stack, Typography } from '@dizzbo/ui';

import { useGetRouteSection } from '@core/hooks';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  sectionUUID: UUIDType;
};

export const StopSectionSummary: React.FC<Props> = ({ sectionUUID }) => {
  const { selectedOrderData } = useTourOrdersFullData();
  const { route: routeUUID } = selectedOrderData;
  const { data, isPending } = useGetRouteSection(routeUUID, sectionUUID);

  const formatDuration = (duration: number) => {
    return dayjs.duration(duration, 'seconds').format('D [d] H [h] m [min]');
  };

  const { t } = useTranslation();

  return (
    <>
      {isPending ? (
        <Skeleton variant="rounded" width={210} height={20} />
      ) : (
        <Stack spacing={2} direction="row" sx={{ paddingTop: 6 }}>
          <Typography variant="footnote" color="primary">
            <Distance value={data?.distance / 1000} />
          </Typography>
          <Typography variant="footnote" color="primary">
            {formatDuration(data?.duration)}
          </Typography>
          <Typography variant="footnote" color="primary">
            {t('toll')} <Currency value={data?.toll} />
          </Typography>
        </Stack>
      )}
    </>
  );
};

StopSectionSummary.displayName = 'StopSectionSummary';

export default StopSectionSummary;
