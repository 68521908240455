import Fade from '@mui/material/Fade';
import React, { FC } from 'react';

import { Box } from '@dizzbo/ui';
import { LoadingPlan } from '@order-detail/components/LoadingPlan';
import { Contributors } from '@order-detail/components/OrderDetailForm/Contributors';
import { CustomerAssignment } from '@order-detail/components/OrderDetailForm/CustomerAssignment';
import { CustomerPrice } from '@order-detail/components/OrderDetailForm/CustomerPrice';
import { OrderInformation } from '@order-detail/components/OrderDetailForm/OrderInformation';

import { CreateOrderFormSection } from './CreateOrderFormSection';

interface Props {}

export const CreateOrderForm: FC<Props> = () => {
  return (
    <Fade in={true}>
      <Box>
        <CreateOrderFormSection sectionNumber={1}>
          <CustomerAssignment orientation="horizontal" />
        </CreateOrderFormSection>

        <CreateOrderFormSection sectionNumber={2}>
          <LoadingPlan />
        </CreateOrderFormSection>

        <CreateOrderFormSection sectionNumber={3}>
          <CustomerPrice orientation="horizontal" />
        </CreateOrderFormSection>

        <CreateOrderFormSection sectionNumber={4}>
          <OrderInformation orientation="horizontal" />
        </CreateOrderFormSection>

        <CreateOrderFormSection sectionNumber={5}>
          <Contributors />
        </CreateOrderFormSection>

        {/* <CreateOrderFormSection sectionNumber={6}>
          <ProofOfDeliveryDocuments orderUUID={orderData.uuid} locked={false} />
        </CreateOrderFormSection>

        <CreateOrderFormSection sectionNumber={7} lastSection>
          <Documents orderUUID={orderData.uuid} />
        </CreateOrderFormSection> */}
      </Box>
    </Fade>
  );
};
