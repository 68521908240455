import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { TourType, UserType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { ParticipantsAutoCompleteTextField } from './ParticipantsAutoCompleteTextField';

type Props = {
  tourUUID: UUIDType;
  popupState: PopupState;
  assignees: UserType[];
};

export const EditAssigneesDialog: React.FC<Props> = ({
  tourUUID,
  popupState,
  assignees,
}: Props) => {
  const { localExistingOrderData, updateLocalExistingOrderData } =
    useTourOrdersFullData();
  const { tourData } = localExistingOrderData;

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      // we are using a array of assignees because we expect this will change in future
      // to multiple assignees. Currently only one assignee is supported.
      // Thats why we are picking the first assignee on submit.
      assignees: assignees,
    },
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    // reset the form if the dialog is not open.
    // Because this Dialog doesn't unmount.
    if (!popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  const { mutate: mutateTour, isPending } = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) => {
      return updateTour({ tourUUID: tourUUID, tourData: values });
    },
  });

  const { t } = useTranslation();

  const onSubmit = (formData) => {
    const oldUserUUIDs = assignees.map((user?: UserType) => {
      return user?.uuid;
    });
    const newUserUUIDs = formData.assignees.map((user: UserType) => {
      return user.uuid;
    });
    const newAssignees = [...newUserUUIDs, ...oldUserUUIDs];
    mutateTour(
      { assignee: newAssignees[0] },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          tourData.assignee = { ...formData.assignees[0] };
          updateLocalExistingOrderData({
            tourData,
          });
          toast.success(t('assigneeChanged'));
          popupState.close();
        },
        onError: () => {
          toast.error(t('couldNotChangeAssignee'));
          popupState.close();
        },
      }
    );
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        {t('changeAssignee')}
      </DialogTitle>
      <DialogContent dividers>
        <form id="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <Controller
              name="assignees"
              control={control}
              defaultValue={[]}
              rules={{ required: false }}
              render={({ field }) => (
                <ParticipantsAutoCompleteTextField
                  label={t('assignee')}
                  {...field}
                />
              )}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isPending}
            type="submit"
            form="form"
            disabled={!isDirty || !isValid}
          >
            {t('apply')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
