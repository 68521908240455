import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = Intl.NumberFormatOptions;

/**
 * Provides localized number formatting for the current locale. Automatically updates when the locale changes
 * and handles caching of the number formatter for performance.
 *
 * @param {Props} options - Formatting options for the number formatter.
 *
 * @return {Intl.NumberFormat} A localized number formatter instance.
 */
export const useNumberFormatter = (options: Props = {}): Intl.NumberFormat => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  return useMemo(
    () => new Intl.NumberFormat(locale, options),
    [locale, options]
  );
};
