import { styled } from '@mui/material/styles';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useState } from 'react';

import { Box, Button, CardActions, CardContent, PlusIcon } from '@dizzbo/ui';

import { useTranslation } from 'react-i18next';
import { MapDialog } from './MapDialog';
import { MapPreview } from './MapPreview';
import { StopList } from './StopList';
import { UnassignedDeliveryList } from './UnassignedDeliveryList';

const CardContentStyles = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 0,
  justifyContent: 'space-between',
}));

const StopListWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
}));

const MapWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '12px',
  '& .H_imprint': {
    display: 'none',
  },
  '& .H_l_bottom.H_l_right': {
    display: 'none',
  },
}));

interface Props {}

export const Stops: FC<Props> = () => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'routeSettingsDialog',
  });

  const [displayAddStopItem, setDisplayAddStopItem] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <CardContentStyles>
        <StopListWrapperStyles>
          <StopList
            setDisplayAddStopItem={setDisplayAddStopItem}
            displayAddStopItem={displayAddStopItem}
          />
        </StopListWrapperStyles>
        <MapWrapperStyles>
          <MapPreview popupState={popupState} />
        </MapWrapperStyles>
      </CardContentStyles>
      <UnassignedDeliveryList />
      <CardActions>
        <Button
          startIcon={<PlusIcon />}
          size="medium"
          variant="tertiary"
          onClick={() => setDisplayAddStopItem(true)}
          disabled={displayAddStopItem}
        >
          {t('addStop')}
        </Button>
      </CardActions>

      <MapDialog popupState={popupState} />
    </>
  );
};
