import { styled, useTheme } from '@mui/material/styles';
import React, { FC, MouseEventHandler } from 'react';

import { Avatar } from '../Avatar';
import { Box } from '../Box';
import { Button } from '../Button';
import { Card } from '../Card';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

import { OrderSubtodoType, UUIDType } from 'packages/core/types';
import { AssignIcon } from '../icons';

const BannerTodoItemWrapperStyles = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<any>(() => {
  const statusStyles = {
    // backgroundColor: disabled
    //   ? theme.styles.bannerTodoItem.disabled.backgroundColor
    //   : theme.styles.bannerTodoItem.default.backgroundColor,
  };

  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '20px',
    ...statusStyles,
  };
});

const TodoItemStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})({
  display: 'flex',
  height: 56,
  paddingTop: 12,
  paddingRight: 24,
  paddingBottom: 12,
  paddingLeft: 24,
  alignItems: 'center',
});

const TitleStyles = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})({
  marginLeft: 8,
});

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})({ marginLeft: 'auto' });

const SubtodosWrapperStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})({
  paddingTop: 0,
  paddingRight: 24,
  paddingBottom: 12,
  paddingLeft: 24,
});

type Props = {
  label: string;
  username?: string;
  buttonLabel: string;
  disableAction: boolean;
  buttonAction?: MouseEventHandler<HTMLButtonElement>;
  subTodos: Array<OrderSubtodoType>;
  uuid: UUIDType;
};

export const BannerTodoItem: FC<Props> = ({
  label,
  username,
  buttonLabel,
  disableAction = false,
  buttonAction,
  subTodos = [],
  uuid,
}) => {
  const theme = useTheme();

  const disabled = disableAction ? subTodos.length > 0 : false;

  const cardProps = disabled
    ? {
        sx: {
          backgroundColor: theme.styles.bannerTodoItem.disabled.backgroundColor,
        },
        elevation: 0,
      }
    : {
        sx: {
          backgroundColor: theme.styles.bannerTodoItem.default.backgroundColor,
        },
        elevation: 1,
      };

  return (
    <BannerTodoItemWrapperStyles {...cardProps} disabled={disabled}>
      <TodoItemStyles>
        <Avatar
          username={username}
          sx={{ width: 32, height: 32, ...theme.typography.bodyBoldSmall }}
        />
        <TitleStyles variant="bodyBold" color="primary">
          {label}
        </TitleStyles>
        <ActionsStyles
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Button
            size="medium"
            variant="primary"
            startIcon={<AssignIcon />}
            onClick={buttonAction}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </ActionsStyles>
      </TodoItemStyles>
      {subTodos.length > 0 && (
        <SubtodosWrapperStyles>
          {subTodos.map((subTodo, index) => (
            <Typography
              key={`uuid_subtodo_${subTodo.uuid}_subTodo_${subTodo.field}_${index}`}
              component={'span'}
            >
              {subTodo.fieldLabel && `${subTodo.fieldLabel}:`} {subTodo.message}
            </Typography>
          ))}
        </SubtodosWrapperStyles>
      )}
    </BannerTodoItemWrapperStyles>
  );
};
