import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { updateOrderDelivery } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Box,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  MultiLineTextfield,
  Select,
  Stack,
  TextField,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { DeliveryType, StopType } from '@types';
import { useTranslation } from 'react-i18next';
import { DeliveryDetailHeader } from './DeliveryDetailHeader';
import { DeliveryGoodsSection } from './DeliveryGoodsSection';
import { DeliveryLoadingPointItem } from './DeliveryLoadingPointItem';

const CardContentStyles = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 0,
}));

const LoadingPointListStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const schema = yup.object().shape({
  packagingRequirements: yup.string(),
  loadingReference: yup.string().max(20, 'Must be max 20 digits'),
  unloadingReference: yup.string().max(20, 'Must be max 20 digits'),
  loadingNotes: yup.string(),
  unloadingNotes: yup.string(),
});

type Props = {
  deliveryData: DeliveryType;
  setActiveTab: (value: number) => void;
};

export const DeliveryDetail: FC<Props> = ({ deliveryData, setActiveTab }) => {
  const { selectedOrderUUID } = useTourOrdersFullData();
  const {
    uuid: deliveryUUID,
    packagingRequirements,
    loadingReference,
    unloadingReference,
    loadingNotes,
    unloadingNotes,
  } = deliveryData;

  const loadingStop: StopType | undefined =
    deliveryData?.loadingStop || undefined;
  const unloadingStop: StopType | undefined =
    deliveryData?.unloadingStop || undefined;
  const queryClient = useQueryClient();

  const {
    mutate: mutateDelivery,
    isError,
    error,
  }: any = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERIES,
      deliveryData.uuid,
    ],
    mutationFn: (values: any) =>
      updateOrderDelivery(selectedOrderUUID, deliveryData.uuid, values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, selectedOrderUUID, QueryKeys.DELIVERIES],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TODOS, selectedOrderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS, selectedOrderUUID],
      });
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      packagingRequirements: packagingRequirements,
      loadingReference: loadingReference,
      unloadingReference: unloadingReference,
      loadingNotes: loadingNotes,
      unloadingNotes: unloadingNotes,
    },
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: any) => {
    mutateDelivery(formData);
  };

  const { t } = useTranslation();

  return (
    <>
      <CardContentStyles>
        <Stack spacing={3} direction="column" width="100%">
          <Box pl={6} pr={6}>
            <DeliveryDetailHeader
              deliveryUUID={deliveryUUID}
              deliveryIndex={deliveryData.index}
              setActiveTab={setActiveTab}
            />
          </Box>
          <LoadingPointListStyles>
            <DeliveryLoadingPointItem
              index={0}
              action="loading"
              stop={loadingStop}
              deliveryUUID={deliveryUUID}
            />
            <DeliveryLoadingPointItem
              index={1}
              lastItem
              action="unloading"
              stop={unloadingStop}
              deliveryUUID={deliveryUUID}
            />
          </LoadingPointListStyles>
        </Stack>
      </CardContentStyles>
      <DeliveryGoodsSection
        deliveryUUID={deliveryData.uuid}
        deliveryIndex={deliveryData.index}
      />
      <CardContent sx={{ borderTop: '0 !important' }}>
        <Stack spacing={6}>
          <Stack spacing={6} direction="row">
            <Controller
              name="packagingRequirements"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="unit-of-measurement-class-label">
                    {t('packagingRequirements')}
                  </InputLabel>
                  <Select
                    labelId="unit-of-measurement-class-label"
                    id="unit-of-measurement"
                    label="unit-of-measurement"
                    {...field}
                  >
                    <MenuItem value="">No Requirements</MenuItem>
                    <MenuItem value="PACKAGING_NEUTRAL">
                      {t('neutralPackaging')}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="loadingReference"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Loading reference"
                  fullWidth
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="unloadingReference"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  label="Unloading reference"
                  fullWidth
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack spacing={6} direction="row">
            <Controller
              name="loadingNotes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MultiLineTextfield
                  label="Loading notes"
                  characterLimit={600}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="unloadingNotes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MultiLineTextfield
                  label="Unloading notes"
                  characterLimit={600}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
          </Stack>
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
    </>
  );
};
