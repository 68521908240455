import React, { FC, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { useGetOrderStops } from '@core/hooks';

import { StopAddItem } from './StopAddItem';

import { useIsLoadedOnce } from '@dizzbo/core/hooks';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { StopType } from '@types';
import { StopListItem } from './StopListItem';

type Props = {
  setDisplayAddStopItem: (value: boolean) => void;
  displayAddStopItem: boolean;
};

export const StopList: FC<Props> = ({
  setDisplayAddStopItem,
  displayAddStopItem,
}: Props) => {
  const { selectedOrderData } = useTourOrdersFullData();
  const { uuid: orderUUID } = selectedOrderData;
  const { data: orderStopsData, isFetching } = useGetOrderStops(orderUUID);
  const [stopsData, setStopsData] = useState<Array<StopType>>([]);

  const { isLoadedOnce } = useIsLoadedOnce(isFetching, !!orderStopsData);

  useEffect(() => {
    if (!isFetching) {
      setStopsData([...orderStopsData]);
    }
  }, [isFetching]);

  const previousStops = usePrevious(stopsData);

  useEffect(() => {
    if (stopsData) {
      const newStopAdded = previousStops?.length < stopsData.length;
      if (newStopAdded && stopsData.length > 1) {
        setDisplayAddStopItem(false);
        return;
      }
      // insufficent stops
      if (stopsData.length < 2) {
        setDisplayAddStopItem(true);
      }
    }
  }, [previousStops, stopsData]);

  return isLoadedOnce ? (
    <>
      {stopsData.map((stop, index) => (
        <StopListItem
          index={index}
          key={stop.uuid}
          stopUUID={stop.uuid}
          isLastItem={index === stopsData.length - 1}
        />
      ))}
      {/* Add Stop Items */}
      {stopsData.length === 0 ? <StopAddItem index={0} /> : null}
      {displayAddStopItem ? (
        <StopAddItem index={stopsData.length || 1} />
      ) : null}
    </>
  ) : null;
};

export default StopList;
