import React, { FC } from 'react';

import { useLocalTranslation } from '@dizzbo/core';
import { useWorkspaces } from '@dizzbo/core/hooks';
import {
  Box,
  CheckIcon,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
} from '@dizzbo/ui';
import { FilterConfig, FilterType } from '@types';

type Props = {
  filterConfig: FilterConfig;
  initialFilterValues?: Array<FilterType>;
  filterType: string;
};

export const FilterMenuList: FC<Props> = ({
  filterConfig,
  filterType,
  initialFilterValues,
}) => {
  const { lt } = useLocalTranslation();

  const { activeWorkspaceUUID, setWorkspaceSettings, removeWorkspaceSettings } =
    useWorkspaces();

  const handleToggle = (currentFilter: FilterType) => () => {
    const currentIndex = initialFilterValues.findIndex(
      (i: FilterType) => i.value === currentFilter.value
    );
    const newStatusValues = [...initialFilterValues];

    if (currentIndex === -1) {
      newStatusValues.push(currentFilter);
    } else {
      newStatusValues.splice(currentIndex, 1);
    }
    if (newStatusValues && newStatusValues !== initialFilterValues) {
      if (newStatusValues.length > 0) {
        setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
          [filterType]: newStatusValues,
        });
      } else {
        removeWorkspaceSettings(activeWorkspaceUUID, 'filters', filterType);
      }
    }
  };

  return (
    <>
      {filterConfig.map(({ groupName, key, filters }, index) => [
        groupName && index > 0 && <Divider key={`divider-${key}`} />,
        groupName && <ListSubheader key={key}>{lt(groupName)}</ListSubheader>,
        filters.map((filter) => [
          <MenuItem
            key={filter.value}
            onClick={handleToggle({ name: filter.name, value: filter.value })}
          >
            <ListItemButton role="button" dense>
              {filter.icon && <ListItemIcon>{filter.icon}</ListItemIcon>}
              <ListItemText>{lt(filter.name)}</ListItemText>

              {initialFilterValues &&
                initialFilterValues.findIndex(
                  (i: FilterType) => i.value === filter.value
                ) !== -1 && (
                  <ListItemIcon>
                    <CheckIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                )}
            </ListItemButton>
            {filter.badge && (
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {filter.badge}
              </Box>
            )}
          </MenuItem>,
        ]),
      ])}
    </>
  );
};
