export function assertNever(value: never) {
  throw new Error('Unexpected value:', value);
}

export function unwrap<T>(
  value: T | null | undefined,
  variableName?: string,
  fileName?: string
): T {
  if (value === null || value === undefined) {
    throw new Error(
      `Error ${fileName ? `in file ${fileName}` : ''}! ${variableName || 'A variable'} is ${value}!`
    );
  }

  return value;
}
