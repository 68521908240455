import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';

import { LoadingPlan } from '../LoadingPlan';
import { Contacts } from './Contacts';
import { Contributors } from './Contributors';
import { CustomerAssignment } from './CustomerAssignment';
import { CustomerPrice } from './CustomerPrice';
import { Documents } from './Documents';
import { OrderInformation } from './OrderInformation';
import { OrderSummary } from './OrderSummary';
import { ProofOfDeliveryDocuments } from './ProofOfDeliveryDocuments';
import { Requirements } from './Requirements';

type Props = {};

export const OrderDetailForm: React.FC<Props> = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={6}
      rowSpacing={12}
      sx={{
        margin: 0,
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }}
    >
      <Grid xs={12}>
        <OrderSummary />
      </Grid>
      <Grid xs={12}>
        <Requirements />
      </Grid>
      <Grid xs={12} lg={4}>
        <CustomerAssignment />
      </Grid>
      <Grid xs={12} lg={4}>
        <OrderInformation />
      </Grid>
      <Grid xs={12} lg={4}>
        <CustomerPrice />
      </Grid>
      <Grid xs={12}>
        <LoadingPlan />
      </Grid>
      <Grid xs={6} lg={6}>
        <Contacts />
      </Grid>
      <Grid xs={6} lg={6}>
        <Contributors />
      </Grid>
      <Grid xs={12}>
        <ProofOfDeliveryDocuments />
      </Grid>
      <Grid xs={12}>
        <Documents />
      </Grid>
    </Grid>
  );
};
