import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getDeliveryGoodList } from '@core/api';
import { QueryKeys } from '@core/config';
import { Card, CardContent } from '@dizzbo/ui';

import { DeliveryGoodDetail } from './DeliveryGoodDetail';
import { DeliveryGoodsTabs } from './DeliveryGoodsTabs';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { UUIDType } from '@types';

const GoodsCardContentStyles = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderTop: '0 !important',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </Fade>
  );
};

type Props = {
  deliveryUUID: UUIDType;
  deliveryIndex: number;
};

export const DeliveryGoodsSection: React.FC<Props> = ({
  deliveryUUID,
  deliveryIndex,
}): ReactElement => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const { selectedOrderData, selectedOrderUUID } = useTourOrdersFullData();
  const orderReference: string = selectedOrderData.reference || '';
  const deliveryIndexString: string = deliveryIndex?.toString() || '';

  const { data: deliveryGoodsData = [], isPending } = useQuery({
    queryKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
      deliveryUUID,
    ],
    queryFn: () => getDeliveryGoodList(deliveryUUID),
    enabled: !!deliveryUUID,
  });

  if (isPending) {
    return null;
  }

  const tabs = deliveryGoodsData.length
    ? deliveryGoodsData.map(
        (deliveryGood) =>
          `${t('good')} ${deliveryIndexString} - ${deliveryGood.alphaIndex}`
      )
    : [`${t('good')} ${deliveryIndexString} - A`];

  return (
    <GoodsCardContentStyles>
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <DeliveryGoodsTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          deliveryUUID={deliveryUUID}
          tabs={tabs}
        />
        {deliveryGoodsData.length === 0 ? (
          <TabPanel value={activeTab} index={0}>
            <DeliveryGoodDetail
              orderUUID={selectedOrderUUID}
              deliveryUUID={deliveryUUID}
              title={`${t('good')} ${orderReference} - ${deliveryIndexString} - A`}
              setActiveTab={setActiveTab}
            />
          </TabPanel>
        ) : (
          deliveryGoodsData.map((deliveryGood, index) => (
            <TabPanel key={deliveryGood.uuid} value={activeTab} index={index}>
              <DeliveryGoodDetail
                orderUUID={selectedOrderUUID}
                deliveryUUID={deliveryUUID}
                deliveryGoodData={deliveryGood}
                title={`${t('good')} ${orderReference} - ${deliveryIndexString} - ${
                  deliveryGood.alphaIndex
                }`}
                setActiveTab={setActiveTab}
              />
            </TabPanel>
          ))
        )}
      </Card>
    </GoodsCardContentStyles>
  );
};
