import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { toast } from 'react-toastify';

import {
  AvatarListItem,
  Card,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { OrderType, UserType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

const ParticipantsListItemWrapperStyles = styled(Card)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: 40,
  alignItems: 'center',
}));

type Props = {
  username: string;
  userUUID: UUIDType;
  orderUUID: UUIDType;
  participants: UserType[];
};

export const ParticipantsListItem: React.FC<Props> = ({
  username,
  userUUID,
  orderUUID,
  participants,
}: Props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const {
    contextType,
    localExistingOrderData,
    localNewOrderData,
    updateLocalExistingOrderData,
    updateLocalNewOrderData,
    selectedOrderUUID,
  } = useTourOrdersFullData();

  const { mutate: mutateOrder }: any = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.PARTICIPANTS],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const handleDelete = (popupState) => {
    const userUUIDs = participants.map((user: UserType) => {
      return user.uuid;
    });
    const index = userUUIDs.indexOf(userUUID);
    userUUIDs.splice(index, 1);
    const remainingParticipants: Array<UserType> = [
      ...participants.filter(
        (participant: UserType) => participant.uuid !== userUUID
      ),
    ];

    mutateOrder(
      { participants: userUUIDs },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.PARTICIPANTS],
          });
          toast.success(`Participant removed.`);
          if (contextType === 'existingOrder') {
            const { tourOrders } = localExistingOrderData;
            tourOrders[selectedOrderUUID].participants = remainingParticipants;
            updateLocalExistingOrderData({
              tourOrders,
            });
          } else {
            const { order } = localNewOrderData;
            order.participants = remainingParticipants;
            updateLocalNewOrderData({
              order,
            });
          }
          popupState.close();
        },
        onError: () => {
          toast.error(`Couldn't remove Participant.`);
          popupState.close();
        },
      }
    );
  };

  const { t } = useTranslation();

  return (
    <ParticipantsListItemWrapperStyles>
      <AvatarListItem
        username={username}
        avatarSx={{
          backgroundColor: theme.palette.green[100],
          width: 32,
          height: 32,
          ...theme.typography.bodyBoldSmall,
        }}
      />

      <Stack sx={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="participant-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{`${t('remove')} ${username}`}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Stack>
    </ParticipantsListItemWrapperStyles>
  );
};
