import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  WarningIcon,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { CustomerType } from '@types';
import { CustomerSlot } from './CustomerSlot';
import { ReferenceNumbersForm } from './ReferenceNumbersForm';

type Props = {
  orientation?: 'horizontal' | 'vertical';
};

export const CustomerAssignment: React.FC<Props> = ({
  orientation = 'vertical',
}) => {
  const { selectedOrderData } = useTourOrdersFullData();

  const { uuid, invoiceReference, invoiceNotes } = selectedOrderData;
  const { t } = useTranslation();

  const loader: CustomerType | undefined = selectedOrderData?.loader;

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title={t('customerAssignment')} variant="small" />
      <CardContent>
        <Stack spacing={6}>
          {!loader ? (
            <Alert
              title={t('noCustomerAssigned')}
              variant="standard"
              severity="warning"
              icon={<WarningIcon />}
            />
          ) : null}
          <CustomerSlot orderUUID={uuid} customer={loader} />
          <Divider />
          <ReferenceNumbersForm
            orderUUID={uuid}
            invoiceReference={invoiceReference}
            invoiceNotes={invoiceNotes}
            hasCustomer={!!loader}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
