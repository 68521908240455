import React, { FC } from 'react';

import {
  Chip,
  DocumentContractIcon,
  DocumentOrderIcon,
  LoadIcon,
  UnloadingIcon,
  UploadIcon,
  ViewIcon,
} from '@dizzbo/ui';

import { useTodoCount } from '@orders/hooks/useTodoCount';
import { FilterConfig } from '@types';

type Props = {
  todo: string;
};

const OrderTdosBadge: FC<Props> = ({ todo }) => {
  const { todosCountGrouped } = useTodoCount();
  const count = todosCountGrouped[todo];
  return (
    count > 0 && (
      <Chip label={todosCountGrouped[todo]} size="small" variant="oxford-40" />
    )
  );
};

export const TodosFilterConfig: FilterConfig = [
  {
    groupName: 'filters_filterOrderPlanningTodos',
    key: 'todos',
    filters: [
      {
        name: 'confirmOrder',
        value: 'order_confirmation_todo',
        icon: <DocumentOrderIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="order_confirmation_todo" />,
      },
      {
        name: 'confirmTransportContract',
        value: 'transport_contract_todo',
        icon: <DocumentContractIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="transport_contract_todo" />,
      },
    ],
  },
  {
    groupName: 'filters_filterOrderExecutionTodos',
    key: 'todos',
    filters: [
      {
        name: 'submitActualLoadingTime',
        value: 'loading_time_todo',
        icon: <LoadIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="loading_time_todo" />,
      },
      {
        name: 'submitActualUnloadingTime',
        value: 'unloading_time_todo',
        icon: <UnloadingIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="unloading_time_todo" />,
      },
      {
        name: 'uploadPoD',
        value: 'pod_upload_todo',
        icon: <UploadIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="pod_upload_todo" />,
      },
      {
        name: 'reviewPoD',
        value: 'pod_review_todo',
        icon: <ViewIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="pod_review_todo" />,
      },
      {
        name: 'reviewOrder',
        value: 'order_review_todo',
        icon: <ViewIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="order_review_todo" />,
      },
      {
        name: 'reviewTour',
        value: 'tour_review_todo',
        icon: <ViewIcon sx={{ width: 16, height: 16 }} />,
        badge: <OrderTdosBadge todo="tour_review_todo" />,
      },
    ],
  },
];
