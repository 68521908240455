import { useQuery } from '@tanstack/react-query';

import { getTourTasks } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetTourTasks = (tourUUID: UUIDType) => {
  return useQuery({
    queryKey: [QueryKeys.TOUR_TASKS, tourUUID],
    queryFn: () => getTourTasks(tourUUID),
    enabled: !!tourUUID,
  });
};
