import {
  Box,
  IconBullet,
  LoadingAndUnloadingIcon,
  LoadingIcon,
  LocationIcon,
  UnloadingIcon,
} from '@dizzbo/ui';
import { VariantTypes } from '@dizzbo/ui/components/IconBullet/IconBullet';
import { SxProps } from '@mui/material/styles';
import React, { FC } from 'react';

type Props = {
  index: number;
  actions?: Array<string>;
};

export const LoadingPointBullet: FC<Props> = ({ index, actions = [] }) => {
  let icon = <LocationIcon />;

  const iconBulletProps: {
    variant: VariantTypes;
  } = {
    variant: 'secondary',
  };

  if (actions.includes('unloading')) {
    iconBulletProps.variant = 'bright';
    icon = <UnloadingIcon />;
  }

  if (actions.includes('loading')) {
    iconBulletProps.variant = 'brightDark';
    icon = <LoadingIcon />;
  }

  if (['unloading', 'loading'].every((i) => actions.includes(i))) {
    iconBulletProps.variant = 'mixed';
    icon = <LoadingAndUnloadingIcon />;
  }

  const indexIndicatorBoxStyles: SxProps = {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alingItems: 'center',
  };

  const indexBoxStyles: SxProps = {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Box sx={{ ...indexIndicatorBoxStyles }}>
      <IconBullet size="large" {...iconBulletProps}>
        <Box sx={{ ...indexBoxStyles }}>{index + 1}</Box>
        {icon}
      </IconBullet>
    </Box>
  );
};

export default LoadingPointBullet;
