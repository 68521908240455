import { useEffect, useState } from 'react';

export function useIsLoadedOnce(isLoading: boolean, hasData: boolean) {
  const [isLoadedOnce, setIsLoadedOnce] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && !isLoadedOnce && hasData) {
      setIsLoadedOnce(true);
    }
  }, [isLoading, isLoadedOnce, hasData]);

  return { isLoadedOnce };
}
