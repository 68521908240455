import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { View } from '@dizzbo/core/views';
import { LoadingScreen, Stack, StickyBox } from '@dizzbo/ui';

import { OrderDetailForm } from '../components/OrderDetailForm';
import { OrderDetailHeader } from '../components/OrderDetailHeader';
import { OrderDetailNavigation } from '../components/OrderDetailNavigation';
import { Settings } from '../components/Settings';
import { TourDetailForm } from '../components/TourDetailForm';
import {
  OrderDetailNavigationProvider,
  TourOrdersFullDataProvider,
  useOrderDetailNavigation,
} from '../contexts';

import { useIsLoadedOnce } from '@dizzbo/core/hooks';
import { useSearchForOrder } from '@orders/queries';
import { OrderType, UUIDType } from '@types';
import { rearrangeOrderUUIDS } from '@utils';
import { useTranslation } from 'react-i18next';
import { TodosSection } from '../components/TodosSection';
import { useGetOrder, useGetTour } from '../queries';

interface TabPanelProps extends PropsWithChildren {
  index: number;
}

type Props = {};

export const OrderDetailView: FC<Props> = () => {
  const containerRef = React.useRef();
  const theme = useTheme();
  const { t } = useTranslation();
  const { uuid: urlOrderUUID } = useParams();

  const {
    data: orderData,
    refetch: refetchOrderData,
    isPending: isOrderDataFetching,
  } = useGetOrder(urlOrderUUID);

  const tourReference: string = orderData?.tour?.reference || '';
  const tourUUID: UUIDType = orderData?.tour?.uuid || '';

  const {
    data: tourData,
    refetch: refetchTourData,
    isPending: isTourDataFetching,
  } = useGetTour(tourUUID);

  const {
    data: tourOrdersData,
    refetch: refetchTourOrdersData,
    isFetching: isTourOrdersFullDataFetching,
  } = useSearchForOrder({
    search: tourReference,
  });

  const [tourOrders, setTourOrders] = useState<Record<UUIDType, OrderType>>({});
  const [tourOrderUUIDs, setTourOrderUUIDs] = useState<Array<UUIDType>>([]);
  const { isLoadedOnce } = useIsLoadedOnce(
    isTourDataFetching || isTourOrdersFullDataFetching,
    !!tourData && !!tourOrdersData && tourOrdersData.results.length !== 0
  );

  useEffect(() => {
    if (
      !isLoadedOnce &&
      !isTourDataFetching &&
      tourData &&
      !isTourOrdersFullDataFetching &&
      tourOrdersData
    ) {
      if (tourOrdersData.results.length === 0) {
        // make sure that for newly created orders the fetched data is not empty
        refetchTourOrdersData();
        return;
      }

      const tourOrdersArr: Array<OrderType> = tourOrdersData.results;
      const tourOrdersObj: Record<UUIDType, OrderType> = tourOrdersArr.reduce(
        (acc: Record<UUIDType, OrderType>, order: OrderType) => {
          acc[order.uuid] = order;
          return acc;
        },
        {}
      );
      setTourOrders(tourOrdersObj);
      const tourOrderUUIDs: Array<UUIDType> = Object.keys(tourOrdersObj);
      setTourOrderUUIDs(rearrangeOrderUUIDS(tourOrderUUIDs, urlOrderUUID));
    }
  }, [isTourOrdersFullDataFetching, isTourDataFetching]);

  if (isOrderDataFetching || !isLoadedOnce || isTourDataFetching) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View
      title={t('dizzboOrderDetailWithReference', {
        reference: orderData.reference || '',
      })}
      sx={{ height: '100%', overflowY: 'auto', paddingBottom: 6 }}
      ref={containerRef}
    >
      <TourOrdersFullDataProvider
        contextType={'existingOrder'}
        initialDisplayedOrderUUID={urlOrderUUID}
        refetchOrderData={refetchOrderData}
        tourData={tourData}
        refetchTourData={refetchTourData}
        tourOrders={tourOrders}
        refetchTourOrders={refetchTourOrdersData}
        rearrangedTourOrdersUUIDs={tourOrderUUIDs}
        newOrderData={null}
      >
        <OrderDetailNavigationProvider>
          <StickyBox
            container={containerRef}
            zIndex={theme.zIndex.appBar + 3}
            stickTo="top"
            offset={0}
            stuckStyles={{}}
            unstuckStyles={{}}
          >
            <OrderDetailHeader />
          </StickyBox>

          <TodosSection />

          <StickyBox
            container={containerRef}
            zIndex={theme.zIndex.appBar + 4}
            stickTo="top"
            offset={80}
            stuckStyles={{}}
            unstuckStyles={{}}
          >
            <OrderDetailNavigation
              tourReference={tourReference}
              ordersData={tourOrders}
              rearrangedOrderUUIDS={tourOrderUUIDs}
            />
          </StickyBox>
          <TabPanel index={0}>
            <TourDetailForm />
          </TabPanel>
          {tourOrderUUIDs && tourOrderUUIDs.length > 0
            ? tourOrderUUIDs.map((orderUUID: UUIDType, index: number) => {
                return (
                  <TabPanel index={index + 1} key={`order_tab_${orderUUID}`}>
                    <OrderDetailForm />
                  </TabPanel>
                );
              })
            : null}
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            sx={{
              marginTop: theme.spacing(6),
              paddingLeft: theme.spacing(6),
              paddingRight: theme.spacing(6),
            }}
          >
            <Settings />
          </Stack>
        </OrderDetailNavigationProvider>
      </TourOrdersFullDataProvider>
    </View>
  );
};

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, index, ...other } = props;
  const { activeTabIndex } = useOrderDetailNavigation();

  return (
    <Fade in={activeTabIndex === index}>
      <div
        role="tabpanel"
        hidden={activeTabIndex !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {activeTabIndex === index && children}
      </div>
    </Fade>
  );
};
