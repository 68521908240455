import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, Stack } from '@dizzbo/ui';

import { View } from '@dizzbo/core/views';

import { TabNavigation } from '@dizzbo/core/components/Workspaces';
import { useSearchParams } from 'react-router-dom';

import { WorkspaceKeys } from '@core/config';
import { WorkspacesProvider } from '@dizzbo/core/components/Workspaces/contexts';
import { OrderListDataProvider, SearchValueProvider } from '@orders/context';
import { FiltersType, TodoFilterKey } from '@types';
import { CreateOrderButton } from '../components/CreateOrderButton';
import { OrderList } from '../components/OrderList';
import { OrderListSettings } from '../components/OrderListSettings';

const viewTypes = [
  { label: 'List', value: 'list' },
  { label: 'Calendar', value: 'calendar' },
];

export const OrderListView: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewType, setViewType] = useState('list');

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderListSwitch',
  });

  const initialFilters: FiltersType =
    searchParams.size > 0
      ? {
          search: searchParams.get('search') || '',
          status: searchParams.get('status')?.split(',') || [],
          todo: searchParams.get('todo') as TodoFilterKey,
        }
      : undefined;

  function clearInitialFilters() {
    setSearchParams({});
  }

  const handleViewTypeChange = (type: string) => {
    setViewType(type);
    popupState.close();
  };

  return (
    <WorkspacesProvider workspaceNamespace={WorkspaceKeys.ORDER_LIST}>
      <SearchValueProvider>
        <OrderListDataProvider>
          <View title="Orders" sx={{ height: '100%' }}>
            <PageHeader title={t('myOrders')}>
              <Stack spacing={3} direction="row">
                {/* <Button
            variant="secondary"
            size="medium"
            startIcon={
              <>
                <DateIcon />
              </>
            }
            endIcon={
              <>
                <SwitchIcon />
              </>
            }
            {...bindTrigger(popupState)}
          >
            {viewType === 'list' ? 'List' : 'Calendar'}
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            // slotProps={{
            //   paper: { sx: { minWidth: 264 } },
            // }}
          >
            {viewTypes.map((item) => (
              <MenuItem
                key={item.value}
                onClick={() => handleViewTypeChange(item.value)}
              >
                <ListItemButton role="button" dense>
                  <ListItemText>{item.label}</ListItemText>
                </ListItemButton>
              </MenuItem>
            ))}
          </Menu>
          <Divider orientation="vertical" variant="middle" flexItem /> */}

                <CreateOrderButton size="medium" />
              </Stack>
            </PageHeader>
            <TabNavigation>
              <OrderListSettings />
              <OrderList />
              {/* {viewType === 'list' && <OrderList />}
        {viewType === 'calendar' && <OrderCalendar />} */}
            </TabNavigation>
          </View>
        </OrderListDataProvider>
      </SearchValueProvider>
    </WorkspacesProvider>
  );
};
