import {
  Box,
  Chip,
  colors,
  List,
  ListItem,
  LoadingIcon,
  LocationIcon,
  Typography,
  UnloadingIcon,
} from '@dizzbo/ui';
import { Grid, SxProps } from '@mui/material';
import {
  DeliveryType,
  LoadingPointType,
  LoadingType,
  RouteSectionType,
  StopType,
} from '@types';
import {
  formatDuration,
  parseDeliveriesData,
  StopDeliveriesIndices,
} from '@utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TourStopsListProps = {
  sections: Array<RouteSectionType>;
  deliveriesData: Record<string, Array<DeliveryType>>;
};

type TourSectionItemProps = {
  section: RouteSectionType;
  parsedDeliveriesData: Record<string, StopDeliveriesIndices>;
  index: number;
  sectionsCount: number;
};

type TourSectionStopItemProps = {
  stop: StopType;
  deliveriesIndices?: StopDeliveriesIndices;
};

type FootnoteProps = {
  section: RouteSectionType;
};

type TourSectionStopItemDetailsProps = {
  loadingPoint: LoadingPointType;
  loadingType: LoadingType | 'none';
};

export const TourStopsList: FC<TourStopsListProps> = ({
  sections,
  deliveriesData,
}) => {
  const { length: sectionsCount } = sections;
  const parsedDeliveriesData: Record<string, StopDeliveriesIndices> =
    parseDeliveriesData(deliveriesData);

  const { t } = useTranslation();

  const listStyles: SxProps = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0',
    padding: '12px 0',
    width: '480px',
    justifyContent: 'flex-start',
    maxHeight: '550px',
    overflowY: 'auto',
  };

  return sectionsCount > 0 ? (
    <Box>
      <List sx={{ ...listStyles }}>
        {sections.map((section: RouteSectionType, index: number) => {
          const { uuid } = section;
          return (
            <TourSectionItem
              section={section}
              parsedDeliveriesData={parsedDeliveriesData}
              index={index}
              sectionsCount={sectionsCount}
              key={`tour_stops_section_${uuid}_${index}`}
            />
          );
        })}
      </List>
    </Box>
  ) : (
    <Box>
      <Typography variant="bodyRegular" color={colors.oxford[100]}>
        {t('loadingDataMissing')}
      </Typography>
    </Box>
  );
};

const TourSectionItem: FC<TourSectionItemProps> = ({
  section,
  parsedDeliveriesData,
  index,
  sectionsCount,
}) => {
  const { origin, destination } = section;

  const listItemStyles: SxProps = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    minHeight: '96px',
    height: 'auto',
    cursor: 'auto',
    padding: '6px 0',
    alignItems: 'start',
  };

  return (
    <>
      {index === 0 ? (
        <ListItem sx={{ ...listItemStyles }}>
          <TourSectionStopItem
            stop={origin}
            deliveriesIndices={parsedDeliveriesData[origin.uuid]}
          />
          <Footnote section={section} />
        </ListItem>
      ) : null}
      <ListItem sx={{ ...listItemStyles }}>
        <TourSectionStopItem
          stop={destination}
          deliveriesIndices={parsedDeliveriesData[destination.uuid]}
        />
        {index !== sectionsCount - 1 ? <Footnote section={section} /> : null}
      </ListItem>
    </>
  );
};

const TourSectionStopItem: FC<TourSectionStopItemProps> = ({
  stop,
  deliveriesIndices,
}) => {
  const loadingDeliveriesIndices: Array<number> =
    deliveriesIndices?.loadingDeliveriesIndices || [];
  const unloadingDeliveriesIndices: Array<number> =
    deliveriesIndices?.unloadingDeliveriesIndices || [];

  const tourSectionItemStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    gap: '8px',
  };

  return deliveriesIndices ? (
    <Box sx={{ ...tourSectionItemStyles }}>
      <Box>
        {unloadingDeliveriesIndices.length > 0 ? (
          <Box>
            {unloadingDeliveriesIndices.map((deliveryIndex: number) => (
              <TourSectionStopItemDetails
                key={`delivery_index_unloading_deliveryIndex_${deliveryIndex}`}
                loadingPoint={stop.loadingPoint}
                loadingType={'unloading'}
              />
            ))}
          </Box>
        ) : null}
      </Box>
      <Box>
        {loadingDeliveriesIndices.length > 0 ? (
          <Box>
            {loadingDeliveriesIndices.map((deliveryIndex: number) => (
              <TourSectionStopItemDetails
                key={`delivery_index_loading_deliveryIndex_${deliveryIndex}`}
                loadingPoint={stop.loadingPoint}
                loadingType={'loading'}
              />
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Box sx={{ ...tourSectionItemStyles }}>
      <TourSectionStopItemDetails
        key={`delivery_index_destination_${stop.uuid}`}
        loadingPoint={stop.loadingPoint}
        loadingType={'none'}
      />
    </Box>
  );
};

const TourSectionStopItemDetails: FC<TourSectionStopItemDetailsProps> = ({
  loadingType,
  loadingPoint,
}) => {
  const { t } = useTranslation();

  const iconStyles: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
  };

  const chipStyles: SxProps = {
    display: 'flex',
    gap: '8px',
    minWidth: '80px',
    minHeight: '40px',
    borderRadius: '21px',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const itemDetailsStyles: SxProps = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  };

  return (
    <Box sx={{ ...itemDetailsStyles }}>
      <Box>
        {loadingType === 'loading' ? (
          <Chip
            sx={{ ...chipStyles }}
            size="large"
            variant="oxford-40"
            icon={<LocationIcon sx={{ ...iconStyles }} />}
            label={<LoadingIcon sx={{ ...iconStyles }} />}
          />
        ) : null}
        {loadingType === 'unloading' ? (
          <Chip
            sx={{ ...chipStyles }}
            size="large"
            variant="green-100"
            icon={<LocationIcon sx={{ ...iconStyles }} />}
            label={<UnloadingIcon sx={{ ...iconStyles }} />}
          />
        ) : null}
        {loadingType === 'none' ? (
          <Chip
            sx={{ ...chipStyles }}
            size="large"
            variant="green-80"
            icon={<LocationIcon sx={{ ...iconStyles }} />}
          />
        ) : null}
      </Box>
      <Grid>
        <Typography variant="labelSmall" color={colors.oxford[25]}>
          {loadingType === 'loading' ? t('loadingPoint') : null}
          {loadingType === 'unloading' ? t('unloadingPoint') : null}
          {loadingType === 'none' ? t('stop') : null}
        </Typography>
        <Box
          sx={{
            maxWidth: '320px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant="bodyRegular" color={colors.oxford[100]}>
            {loadingPoint.address.formatted}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

const Footnote: FC<FootnoteProps> = ({ section }) => {
  const { t } = useTranslation();

  const chipStyles: SxProps = {
    display: 'flex',
    gap: '8px',
    minWidth: '80px',
    minHeight: '40px',
    borderRadius: '21px',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const footnotesStyles: SxProps = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ ...chipStyles }}>
        <Typography variant="h4" color={colors.oxford[40]}>
          ⋮
        </Typography>
      </Box>
      <Box sx={{ ...footnotesStyles }}>
        <Typography variant="footnote" color={colors.oxford[40]}>
          {(section.distance / 1000).toFixed(1)} km
        </Typography>
        <Typography variant="footnote" color={colors.oxford[40]}>
          {formatDuration(section.duration, 'hm')}
        </Typography>
        <Typography variant="footnote" color={colors.oxford[40]}>
          {t('toll')} {section.toll} €
        </Typography>
      </Box>
    </Box>
  );
};
