import { useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomerContacts, getOrderContacts } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LockedIcon,
  PlusIcon,
  Skeleton,
  Stack,
  Typography,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { AddContactsDialog } from './AddContactsDialog';
import { ContactListItem } from './ContactListItem';

type Props = {};

export const Contacts: React.FC<Props> = () => {
  const { selectedOrderData } = useTourOrdersFullData();
  const { uuid, loader } = selectedOrderData;
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'customerContatsAddDialog',
  });

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  const { data: customerContactsData, isPending: isLoadingCustomerContacts } =
    useQuery({
      queryKey: [QueryKeys.ORDERS, uuid, QueryKeys.CUSTOMER_CONTACTS],
      queryFn: () => getCustomerContacts(loader.uuid),
      enabled: !!loader,
    });

  const { data: contactsData, isPending: isLoadingOrderContacts } = useQuery({
    queryKey: [QueryKeys.ORDERS, uuid, QueryKeys.CONTACTS],
    queryFn: () => getOrderContacts(uuid),
    enabled: !!loader,
  });

  const { t } = useTranslation();

  return loader ? (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardHeader title="Contacts" variant="small" />
      <CardContent>
        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Stack spacing={6}>
            <Typography variant="h6">Customer Preset</Typography>
            {!isLoadingOrderContacts && !isLoadingCustomerContacts ? (
              <>
                {customerContactsData.map((contact) => (
                  <ContactListItem key={contact.uuid} contactData={contact} />
                ))}
                {customerContactsData.length === 0 && (
                  <Alert
                    severity="warning"
                    title="Customer data doesn't have any contacts"
                  />
                )}
                <Divider orientation="horizontal" flexItem />
                <Typography variant="h6">
                  {t('orderSpecificOverride')}
                </Typography>

                {contactsData.map((contact) => (
                  <ContactListItem
                    key={contact.uuid}
                    contactData={contact}
                    orderUUID={uuid}
                  />
                ))}
                {contactsData.length === 0 && (
                  <Alert
                    severity="info"
                    variant="standard-neutral"
                    title="There are no order specific contacts."
                  />
                )}
              </>
            ) : (
              <>
                <Skeleton width="100%" height={40} />
                <Skeleton width="100%" height={40} />
                <Skeleton width="100%" height={40} />
              </>
            )}
            <Box>
              <Button
                variant="tertiary"
                size="medium"
                startIcon={<PlusIcon />}
                onClick={handleClick}
              >
                {t('addContacts')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
      <AddContactsDialog
        orderUUID={uuid}
        customerUUID={selectedOrderData?.loader?.uuid}
        popupState={popupState}
      />
    </Card>
  ) : (
    <Card
      variant="filled-disabled"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardHeader
        title="Contacts"
        variant="small"
        action={
          <IconButton aria-label="settings" variant="tertiary">
            <LockedIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Alert title="Locked until a customer is assigned" severity="info" />
      </CardContent>
    </Card>
  );
};
