import SvgIcon from '@mui/material/SvgIcon';
import React, { ComponentProps, FC } from 'react';
import { Box } from '../Box';

type MuiSvgIconProps = ComponentProps<typeof SvgIcon>;

export const HideIcon: FC<MuiSvgIconProps> = (props) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <SvgIcon {...props}>
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3665 10.7303L12.6635 12.3295L13.699 11.4896L4.85107 0.580078L3.81551 1.41995L5.11647 3.02403C4.24893 3.43265 3.48403 3.96994 2.86053 4.48675C2.2264 5.01238 1.71487 5.53648 1.36163 5.92915C1.18462 6.12591 1.04633 6.29081 0.951246 6.40792C0.903677 6.46651 0.866845 6.51323 0.841308 6.5461C0.828537 6.56254 0.818584 6.57552 0.811519 6.58481L0.803093 6.59593L0.800519 6.59935L0.799644 6.60052C0.799644 6.60052 0.799045 6.60132 1.33335 7.00002L0.799045 6.60132L0.501541 7.00002L0.799045 7.39872L0.800519 7.40068L0.803093 7.40411L0.811519 7.41523C0.818584 7.42451 0.828537 7.4375 0.841308 7.45394C0.866845 7.48681 0.903677 7.53353 0.951246 7.59211C1.04633 7.70923 1.18462 7.87412 1.36163 8.07089C1.71487 8.46355 2.2264 8.98766 2.86053 9.51329C4.10989 10.5489 5.92698 11.6667 8.00002 11.6667C9.23498 11.6667 10.3791 11.27 11.3665 10.7303ZM10.5102 9.67455L9.45447 8.37279C9.08983 8.759 8.57306 9.00002 8 9.00002C6.89543 9.00002 6 8.10459 6 7.00002C6 6.27768 6.38293 5.64478 6.95687 5.29325L5.9864 4.09666C5.14612 4.4448 4.37193 4.96579 3.71142 5.51329C3.13912 5.98766 2.67434 6.46355 2.35288 6.82089C2.29541 6.88477 2.24266 6.94471 2.19482 7.00002C2.24266 7.05532 2.29541 7.11527 2.35288 7.17915C2.67434 7.53648 3.13912 8.01238 3.71142 8.48675C4.87492 9.45117 6.39116 10.3334 8.00002 10.3334C8.87849 10.3334 9.72934 10.0703 10.5102 9.67455Z"
            fill="#3B6E71"
          />
          <path
            d="M14.6384 8.07089C14.3412 8.40121 13.9321 8.82456 13.4321 9.26358L12.5906 8.22792C13.0256 7.84283 13.3843 7.47135 13.6472 7.17915C13.7046 7.11527 13.7574 7.05532 13.8052 7.00002C13.7574 6.94471 13.7046 6.88477 13.6472 6.82089C13.3257 6.46355 12.8609 5.98766 12.2886 5.51329C11.3618 4.74505 10.2111 4.02899 8.96815 3.76946L7.80401 2.33667C7.86908 2.33447 7.93442 2.33335 8.00002 2.33335C10.0731 2.33335 11.8902 3.45117 13.1395 4.48675C13.7736 5.01238 14.2852 5.53648 14.6384 5.92915C14.8154 6.12591 14.9537 6.29081 15.0488 6.40792C15.0964 6.46651 15.1332 6.51323 15.1587 6.5461C15.1715 6.56254 15.1815 6.57552 15.1885 6.58481L15.1969 6.59593L15.1995 6.59935L15.2007 6.60097L14.6667 7.00002C15.201 7.39872 15.2004 7.39952 15.2004 7.39952L15.1995 7.40068L15.1969 7.40411L15.1885 7.41523C15.1815 7.42451 15.1715 7.4375 15.1587 7.45394C15.1332 7.48681 15.0964 7.53353 15.0488 7.59211C14.9537 7.70923 14.8154 7.87412 14.6384 8.07089Z"
            fill="#3B6E71"
          />
          <path
            d="M14.6667 7.00002L15.201 7.39872L15.4985 7.00002L15.2007 6.60097C15.2007 6.60097 15.201 6.60132 14.6667 7.00002Z"
            fill="#3B6E71"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
};

HideIcon.displayName = 'HideIcon';
