import React, { FC } from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { VehicleDisplay } from './VehicleDisplay';
import { VehicleForm } from './VehicleForm';

type Props = {};

export const VehicleSlot: FC<Props> = () => {
  const { localExistingOrderData } = useTourOrdersFullData();
  const { tourData } = localExistingOrderData;

  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {tourData.vehicle ? <VehicleDisplay /> : <VehicleForm />}
      </CardContent>
    </Card>
  );
};
