import { getOrderDeliveryList } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { DeliveryType, UUIDType } from '@types';

type MultipleOrdersDeliveriesData = {
  data: Record<UUIDType, Array<DeliveryType>>;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useGetMultipleOrdersDeliveries = (
  orderUUIDs: Array<UUIDType>
): MultipleOrdersDeliveriesData => {
  const queryResults: Array<UseQueryResult<Array<DeliveryType>, Error>> =
    useQueries({
      queries: orderUUIDs.map((orderUUID) => ({
        queryKey: [QueryKeys.DELIVERIES, orderUUID],
        queryFn: () => getOrderDeliveryList(orderUUID),
        enabled: !!orderUUID && orderUUIDs.length > 0,
      })),
    });

  const data: Record<UUIDType, Array<DeliveryType>> = queryResults.reduce(
    (acc, queryResult, index) => {
      const { data: orderDeliveries } = queryResult;
      const orderUUID: UUIDType = orderUUIDs[index];
      acc[orderUUID] = orderDeliveries;
      return acc;
    },
    {}
  );

  function refetch(): void {
    queryResults.forEach((queryResult) => {
      if (queryResult.refetch) {
        queryResult.refetch();
      }
    });
  }

  const isFetching: boolean = queryResults.some((query) => query.isFetching);
  const isError: boolean = queryResults.some((query) => query.isError);

  return {
    data,
    isFetching,
    isError,
    refetch,
  };
};
