import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  AssignIcon,
  Box,
  Button,
  Cell,
  CheckIcon,
  ChevronRightIcon,
  DateIcon,
  DocumentIcon,
  IconBullet,
  IconButton,
  LoadingIcon,
  SquareIcon,
  Stack,
  TableInstance,
  Tooltip,
  TruckIcon,
  UnloadingIcon,
  UploadIcon,
  ViewIcon,
} from '@dizzbo/ui';
import { OrderTodoType, OrderType } from '@types';
import { assertNever, generateTodoPopupStates } from '@utils';
import { useTranslation } from 'react-i18next';
import {
  LoadingTimeDialog,
  OrderConfirmationDialog,
  OrderReviewDialog,
  PoDReviewDialog,
  PoDUploadDialog,
  TourReviewDialog,
  TransportContractDialog,
  UnloadingTimeDialog,
} from '../../Todos';
import { MoveOrderBetweenToursDialog, RemoveOrderDialog } from '../dialogs';
import { OrderActionsButton } from './OrderActionsButton';

type Props = {
  cell: Cell<OrderType>;
  table: TableInstance<OrderType>;
};

export const TodosCell: React.FC<Props> = ({
  cell: {
    row: { original: orderData },
  },
}: Props) => {
  const { t } = useTranslation();
  const {
    uuid: orderUUID,
    todos = [],
    tour,
    status,
    loadType,
    reference: orderReference,
  } = orderData;

  const { reference: tourReference, uuid: tourUUID } = tour;

  const todoPopupStates: Record<OrderTodoType, PopupState> =
    generateTodoPopupStates(tourReference);

  const removeOrderPopupState: PopupState = usePopupState({
    variant: 'dialog',
    popupId: 'removeOrderPopupStateDialog',
  });

  const moveOrderPopupState: PopupState = usePopupState({
    variant: 'dialog',
    popupId: 'moveOrderPopupStateDialog',
  });

  const addAnotherOrderPopupState: PopupState = usePopupState({
    variant: 'dialog',
    popupId: 'addAnotherOrderPopupStateDialog',
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        {todos.map((todo: OrderTodoType) => {
          const popupState: PopupState = todoPopupStates[todo];
          switch (todo) {
            case 'order_confirmation_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('confirmOrder')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<SquareIcon />}
                      endIcon={<DocumentIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <OrderConfirmationDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'transport_contract_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('sendTransportContract')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<AssignIcon />}
                      endIcon={<TruckIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <TransportContractDialog
                    orderData={orderData}
                    tourUUID={tourUUID}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'loading_time_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('setLoadingTime')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<DateIcon />}
                      endIcon={<LoadingIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <LoadingTimeDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'unloading_time_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('setUnoadingTime')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<DateIcon />}
                      endIcon={<UnloadingIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <UnloadingTimeDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'pod_upload_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('uploadPoD')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<UploadIcon />}
                      onClick={() => popupState.open()}
                    >
                      POD
                    </Button>
                  </Tooltip>
                  <PoDUploadDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'pod_review_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('reviewPoD')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<ViewIcon />}
                      endIcon={<DocumentIcon />}
                      onClick={() => popupState.open()}
                    >
                      POD
                    </Button>
                  </Tooltip>
                  <PoDReviewDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'order_review_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('reviewOrder')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<ViewIcon />}
                      endIcon={<DocumentIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <OrderReviewDialog
                    orderData={orderData}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );
            case 'tour_review_todo':
              return (
                <Fragment key={`${orderUUID}_${todo}`}>
                  <Tooltip
                    title={t('reviewTour')}
                    key={`${orderUUID}_${todo}_tooltip`}
                  >
                    <Button
                      variant="primary"
                      size="medium"
                      startIcon={<ViewIcon />}
                      endIcon={<DocumentIcon />}
                      onClick={() => popupState.open()}
                    />
                  </Tooltip>
                  <TourReviewDialog
                    orderData={orderData}
                    tourData={tour}
                    popupState={popupState}
                    key={`${orderUUID}_${todo}_dialog`}
                  />
                </Fragment>
              );

            default:
              assertNever(todo);
              return null;
          }
        })}
        {status === 'REVIEWED' ? (
          <Tooltip title="Order Reviewed">
            <Box>
              <IconBullet variant="success" size="medium">
                <CheckIcon />
              </IconBullet>
            </Box>
          </Tooltip>
        ) : null}
      </Stack>
      <OrderActionsButton
        orderUUID={orderUUID}
        tourReference={tourReference}
        loadType={loadType}
        removeOrderPopupState={removeOrderPopupState}
        moveOrderPopupState={moveOrderPopupState}
        addAnotherOrderPopupState={addAnotherOrderPopupState}
      />
      <IconButton
        variant="secondary"
        size="medium"
        to={`/orders/${orderUUID}`}
        component={RouterLink}
      >
        <ChevronRightIcon />
      </IconButton>
      <MoveOrderBetweenToursDialog
        popupState={moveOrderPopupState}
        orderReference={orderReference}
        tourReference={tourReference}
        targetOrderUUID={orderUUID}
        type={'moveThisOrder'}
      />
      <MoveOrderBetweenToursDialog
        popupState={addAnotherOrderPopupState}
        orderReference={orderReference}
        tourReference={tourReference}
        targetTourUUID={tourUUID}
        type={'addAnotherOrder'}
      />
      <RemoveOrderDialog
        popupState={removeOrderPopupState}
        orderReference={orderReference}
        tourReference={tourReference}
        orderUUID={orderUUID}
      />
    </Stack>
  );
};
