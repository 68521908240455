import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { createDeliveryGood } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Box,
  Divider,
  IconButton,
  PlusIcon,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { UUIDType } from '@types';

function a11yProps(index: number) {
  return {
    id: `delivery-good-tab-${index}`,
    'aria-controls': `delivery-good-tabpanel-${index}`,
  };
}

const ActionStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingRight: 24,
}));

type Props = {
  activeTab: number;
  setActiveTab: (number: number) => void;
  deliveryUUID: UUIDType;
  tabs: string[];
};

export const DeliveryGoodsTabs: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  deliveryUUID,
  tabs = [],
}) => {
  const theme = useTheme();
  const { selectedOrderUUID } = useTourOrdersFullData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const queryClient = useQueryClient();

  const { mutate: mutateDeliveryGood }: any = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      selectedOrderUUID,
      QueryKeys.DELIVERY_GOODS,
    ],
    mutationFn: (values) => createDeliveryGood(deliveryUUID, values),
  });

  const handleAddDeliveryGood = () => {
    mutateDeliveryGood(
      {},
      {
        onSuccess: (data: any, values: any) => {
          queryClient.invalidateQueries({
            queryKey: [
              QueryKeys.ORDERS,
              selectedOrderUUID,
              QueryKeys.DELIVERY_GOODS,
            ],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TODOS, selectedOrderUUID],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TASKS, selectedOrderUUID],
          });
        },
        onError: (error: any, variables: any, context: any) => {},
      }
    );
  };

  return (
    <Stack
      sx={{
        transition: theme.transitions.create('border '),
        paddingLeft: 3,
        position: 'relative',
      }}
      direction="row"
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Loading plan tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={tab} label={tab} {...a11yProps(index + 1)} />
        ))}
      </Tabs>
      <ActionStyles>
        <Divider orientation="vertical" sx={{ height: 32, marginRight: 3 }} />
        <Tooltip title="Add good" placement="top">
          <IconButton variant="tertiary" onClick={handleAddDeliveryGood}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </ActionStyles>
    </Stack>
  );
};
