import { getOrderTodos } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { OrderTodoType, UUIDType } from '@types';

type MultipleOrdersTodosData = {
  data: Record<UUIDType, Array<OrderTodoType>>;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useGetMultipleOrdersTodos = (
  orderUUIDs: Array<UUIDType>
): MultipleOrdersTodosData => {
  const queryResults: Array<UseQueryResult<Array<OrderTodoType>, Error>> =
    useQueries({
      queries: orderUUIDs.map((orderUUID) => ({
        queryKey: [QueryKeys.TODOS, orderUUID],
        queryFn: () => getOrderTodos(orderUUID),
        enabled: !!orderUUID,
      })),
    });

  const data: Record<UUIDType, Array<OrderTodoType>> = queryResults.reduce(
    (acc, queryResult, index) => {
      const { data: orderTodosTasks } = queryResult;
      const orderUUID: UUIDType = orderUUIDs[index];
      acc[orderUUID] = orderTodosTasks;
      return acc;
    },
    {}
  );

  const isFetching: boolean = queryResults.some((query) => query.isFetching);
  const isError: boolean = queryResults.some((query) => query.isError);

  function refetch(): void {
    queryResults.forEach((queryResult) => {
      if (queryResult.refetch) {
        queryResult.refetch();
      }
    });
  }

  return {
    data,
    isFetching,
    isError,
    refetch,
  };
};
