import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createOrderDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import { useGetOrderStops } from '@core/hooks';
import {
  Box,
  Divider,
  IconButton,
  PlusIcon,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from '@dizzbo/ui';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { DeliveryListItemType } from '@types';

function a11yProps(index: number) {
  return {
    id: `loading-plan-tab-${index}`,
    'aria-controls': `loading-plan-tabpanel-${index}`,
  };
}

const ActionStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingRight: 24,
}));

const LoadingPlanTabStyles = styled(Tab)(({ theme }) => ({
  marginRight: 24,
  overflow: 'visible',
  '&::after': {
    content: "' '",
    position: 'absolute',
    borderRight: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
    top: 12,
    bottom: 12,
    right: -12,
  },
}));

type Props = {
  activeTab: number;
  setActiveTab: (value: number) => void;
  deliveriesData: DeliveryListItemType[];
};

export const LoadingPlanTabs: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  deliveriesData = [],
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedOrderUUID: orderUUID } = useTourOrdersFullData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const { data: stopsData } = useGetOrderStops(orderUUID);

  const queryClient = useQueryClient();

  const { mutate: mutateDeliveries }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
    mutationFn: (values) =>
      createOrderDelivery(orderUUID, {
        loadingStop: stopsData[0]?.uuid,
        unloadingStop: stopsData[1]?.uuid,
      }),
  });

  const handleAddDelivery = () => {
    mutateDeliveries(
      {},
      {
        onSuccess: (data: any, values: any) => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TODOS, orderUUID],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TASKS, orderUUID],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.DELIVERIES, orderUUID],
          });
        },
      }
    );
  };

  return (
    <Stack
      sx={{
        transition: theme.transitions.create('border '),
        paddingLeft: 3,
        position: 'relative',
        borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
      }}
      direction="row"
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Loading plan tabs"
        variant="scrollable"
        visibleScrollbar={true}
      >
        <LoadingPlanTabStyles label={t('loadingPlan')} {...a11yProps(0)} />
        {deliveriesData.map((delivery, index) => (
          <Tab
            key={delivery.uuid}
            label={`${t('delivery')} ${delivery.index}`}
            {...a11yProps(index + 1)}
          />
        ))}
      </Tabs>
      <ActionStyles>
        <Divider orientation="vertical" sx={{ height: 32, marginRight: 3 }} />
        <Tooltip title={t('addDelivery')} placement="top">
          <IconButton variant="tertiary" onClick={handleAddDelivery}>
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </ActionStyles>
    </Stack>
  );
};
