import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { Box, Stack } from '@dizzbo/ui';

import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';
import { useCreateOrderStop } from '@core/hooks';

import { LoadingPointBullet } from './LoadingPointBullet';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { LoadingPointType } from '@types';

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering' && prop !== 'isDragging',
})<any>(({ theme, isHovering, isDragging }) => {
  return {
    height: '100%',
    backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };
});

const BulletContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
}));

type Props = {
  index: number;
};

export const StopAddItem = React.forwardRef<HTMLDivElement, Props>(
  ({ index }, ref) => {
    const { selectedOrderUUID, selectedOrderData } = useTourOrdersFullData();
    const { route: routeUUID } = selectedOrderData;
    const { mutateAsync } = useCreateOrderStop(selectedOrderUUID);
    const queryClient = useQueryClient();

    const handleAutocompleteOnChange = async (
      loadingPoint: LoadingPointType
    ) => {
      try {
        await mutateAsync({ loadingPoint: loadingPoint.uuid });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TODOS, selectedOrderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TASKS, selectedOrderUUID],
        });
      } catch (error) {
        console.error('error', error);
      }
    };

    return (
      <Box sx={{ backgroundColor: '#FFFFFF' }} ref={ref}>
        <StopContainerStyles direction="row" py={3} px={6}>
          <BulletContainerStyles>
            <LoadingPointBullet index={index} />
          </BulletContainerStyles>
          <Stack
            spacing={3}
            direction="column"
            ml={6}
            pr={10}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <LoadingPointAutocomplete
              label="Stop"
              onChange={handleAutocompleteOnChange}
              value={null}
              disableClearable={false}
            />
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

StopAddItem.displayName = 'StopAddItem';

export default StopAddItem;
