import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { getOrderProofOfDelivery, getOrderTasks } from '@core/api';
import { QueryKeys } from '@core/config';

import { ProofOfDeliveryReviewDialog } from '@order-detail/components/OrderDetailForm/ProofOfDeliveryDocuments/ProofOfDeliveryReviewDialog';
import { OrderSubtodoType, OrderType } from '@types';

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const PoDReviewDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { uuid: orderUUID } = orderData;
  const queryClient = useQueryClient();

  const { data: orderTasks, isPending: isOrderTasksPending } = useQuery({
    queryKey: [QueryKeys.TASKS, orderUUID],
    queryFn: () => getOrderTasks(orderUUID),
    enabled: popupState.isOpen,
  });

  const podReviewSubTodoUUID: string | undefined = !isOrderTasksPending
    ? orderTasks.pod_review_todo.find(
        (subTodo: OrderSubtodoType) =>
          subTodo.type === 'transports.proofofdelivery'
      )?.uuid
    : undefined;

  const { data: podData, isPending: isPendingPoD } = useQuery({
    queryKey: [QueryKeys.ORDERS, podReviewSubTodoUUID],
    queryFn: () => getOrderProofOfDelivery(orderUUID, podReviewSubTodoUUID),
    enabled: !!podReviewSubTodoUUID,
  });

  if (isPendingPoD || !podData) return null;

  const { uuid: podUUID, file, createdAt, title } = podData;

  const handleSuccess = (): void => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ORDERS],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TASKS],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ORDERS, orderUUID],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TASKS, orderUUID],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TODOS, orderUUID],
    });
  };

  return (
    <ProofOfDeliveryReviewDialog
      podUUID={podUUID}
      orderUUID={orderUUID}
      popupState={popupState}
      file={file}
      fileTitle={title}
      fileDate={createdAt}
      onSuccess={handleSuccess}
    />
  );
};
