import { getOrders } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderType, PaginatedListType } from '@types';

export const useSearchForOrder = (params?: {
  search: string;
}): UseQueryResult<PaginatedListType<OrderType>, Error> => {
  return useQuery({
    queryKey: [QueryKeys.ORDERS, params],
    queryFn: () => getOrders(params),
    enabled: !!params,
  });
};
