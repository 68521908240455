import React, { FC } from 'react';

import { Stack } from '@dizzbo/ui';

import { RequirementSelect } from './RequirementSelect';
import { RequirementsDisplay } from './RequirementsDisplay';

import { useTourOrdersFullData } from '@order-detail/contexts';
import { YesNoDefaultType } from '@types';

type Props = {};

export const Requirements: FC<Props> = () => {
  const { selectedOrderUUID, selectedOrderData } = useTourOrdersFullData();

  const podRequired: YesNoDefaultType | undefined =
    selectedOrderData?.podRequired;
  const paperDocumentsRequired: YesNoDefaultType | undefined =
    selectedOrderData?.paperDocumentsRequired;
  const neutralDelivery: boolean | undefined =
    selectedOrderData?.neutralDelivery;
  const palletExchange: boolean | undefined = selectedOrderData?.palletExchange;
  const podRequiredDefault: boolean | undefined =
    selectedOrderData?.podRequiredDefault;
  const paperDocumentsRequiredDefault: boolean | undefined =
    selectedOrderData?.paperDocumentsRequiredDefault;
  const transportInsuranceValue: number | undefined =
    selectedOrderData?.transportInsuranceValue;

  return (
    <Stack spacing={3} direction="row" flexWrap="wrap" useFlexGap>
      <RequirementSelect
        orderUUID={selectedOrderUUID}
        fieldName="podRequired"
        value={podRequired}
        presetValue={podRequiredDefault}
        label="POD Required:"
      />
      <RequirementSelect
        orderUUID={selectedOrderUUID}
        fieldName="paperDocumentsRequired"
        value={paperDocumentsRequired}
        presetValue={paperDocumentsRequiredDefault}
        label="Original Documents required:"
      />
      <RequirementsDisplay label="Neutral Delivery:" value={neutralDelivery} />
      <RequirementsDisplay label="Pallet Exchange:" value={palletExchange} />
      <RequirementsDisplay
        label="Transport insurance:"
        value={!!transportInsuranceValue}
      />
    </Stack>
  );
};
