import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { toast } from 'react-toastify';

import {
  AvatarListItem,
  Card,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';
import { useTourOrdersFullData } from '@order-detail/contexts';
import { TourType, UserType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

const ParticipantsListItemWrapperStyles = styled(Card)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: 40,
  alignItems: 'center',
}));

type Props = {
  username: string;
  userUUID: UUIDType;
};

export const ParticipantsListItem: React.FC<Props> = ({
  username,
  userUUID,
}: Props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { localExistingOrderData, updateLocalExistingOrderData } =
    useTourOrdersFullData();
  const { tourData } = localExistingOrderData;
  const { uuid: tourUUID } = tourData;
  const participants: Array<UserType> = tourData.participants || [];

  const { mutate: mutateTour }: any = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID, QueryKeys.PARTICIPANTS],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const handleDelete = (popupState) => {
    const userUUIDs = participants.map((user: UserType) => {
      return user.uuid;
    });
    const index = userUUIDs.indexOf(userUUID);
    userUUIDs.splice(index, 1);
    const remainingParticipants: Array<UserType> = participants.filter(
      (participant: UserType) => participant.uuid !== userUUID
    );

    mutateTour(
      { participants: userUUIDs },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          toast.success(t('participantRemoved'));
          localExistingOrderData.tourData.participants = remainingParticipants;
          updateLocalExistingOrderData({
            tourData,
          });

          popupState.close();
        },
        onError: () => {
          toast.error(t('participantRemovalError'));
          popupState.close();
        },
      }
    );
  };

  const { t } = useTranslation();

  return (
    <ParticipantsListItemWrapperStyles>
      <AvatarListItem
        username={username}
        avatarSx={{
          backgroundColor: theme.palette.green[100],
          width: 32,
          height: 32,
          ...theme.typography.bodyBoldSmall,
        }}
      />

      <Stack sx={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="participant-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{`${t('remove')} ${username}`}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Stack>
    </ParticipantsListItemWrapperStyles>
  );
};
